import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMaskPatern } from '../../components';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'quota-input',
  templateUrl: './quotainput.component.html'
})
export class QuotaInputComponent{
  constructor(public client: HttpService) {  }
  @Output()
  public valueChange = new EventEmitter<any>();
  @Input()
  set value(val) {
    if (this._value == val) return;
    if (!this.newValueChange)
      this.newValue = val;
    this._value = val;   
    this.valueChange.emit(this._value);
  }
  get value() {
    return this._value;
  }
  private _value?: any;
  newValue: any;
  newValueChange = false;
  @Output()
  public OnEnterPress = new EventEmitter();
  keypress($event: any) {
    this.newValueChange = true;
  }
  public ApplyQuota() {
    console.log('ApplyQuota');
    let data = JSON.stringify(this.newValue);
    this.client.post<any>('api/payment/quota', data).subscribe((res) => {
      console.log(res);
      this.newValueChange = false;
    });
  }
} 
