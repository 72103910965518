<div class="period-selector"  [ngClass]="isPeriodSelected?'period-selected':''">
  <form class="form-inline" style="margin-block-end: 0;">
    <div class="period-selector-hidden">
      <div class="input-group">
        <input name="datepicker"
               class="form-control"
               ngbDatepicker
               #datepicker="ngbDatepicker"
               [autoClose]="'outside'"
               (dateSelect)="onDateSelection($event, datepicker)"
               [displayMonths]="2"
               [dayTemplate]="t"
               outsideDays="hidden"
               [maxDate]="maxDate"
               [startDate]="startDate">
        <ng-template #t let-date let-focused="focused">
          <span class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>
    <div class="period-selector-dates">
      <div class="calendar-icon" (click)="datepicker.toggle();">
      </div>
      <p *ngIf="fromDate!=null">{{formatter.format(fromDate)}} - {{formatter.format(toDate)}}</p>
      <p *ngIf="fromDate==null">Select period</p>
      <div class="calendar-icon-colse" (click)="ClearPeiod()">
      </div>
    </div>
  </form>
</div>
