<div class="dropdown" [ngClass]="open?'':'dropdown-close'">
  <div class="dropdown-data-wrap">
    <div class="dropdown-content">
      <ng-content></ng-content>
    </div>
  </div>
  <mat-form-field class="mat-dropdown mat-input-suffix-icon mat-input-suffix-dropdown">
    <mat-label>{{label}}</mat-label>
    <input matInput type="text" [(ngModel)]="value" readonly>
    <p [ngClass]="open?'icon-dropup':'icon-dropdown'" matSuffix (click)="open=!open"></p>
  </mat-form-field>
</div>
