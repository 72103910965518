export class RouteNames {
  static Login: any = 'login';
  static Registration: any = 'registration';//registration
  static RegistrationCompleted: any = 'validateemail';
  static FogotPassword: any = 'fogotpassword';
  static ResetPassword: any = 'resetpassword';
  static ContactUs: any = 'contactus';
  static Home: any = '';
  static Dashboard = 'dashboard';
  static Billing = 'billing';
  static AutorizationDisqus: any = 'autorizationdisqus';
  static AutorizationTelegram: any = 'autorizationtelegram';
  static BaseUri = window.location.protocol + '//' + window.location.host + '/';
  static DashboardUri: string = `${RouteNames.BaseUri}${RouteNames.Dashboard}`;
  static AutorizationDisqusUri: string = `${RouteNames.BaseUri}${RouteNames.AutorizationDisqus}`;
  static AutorizationTelegramUri: string = `${RouteNames.BaseUri}${RouteNames.AutorizationTelegram}`;
}
