import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VInputModel, Wizard } from '../../components';
import { CSWizardSettings, CommentSystemForCreationDto,  Customvalidators, Guid, Helper, SiteForCreationDto, SitesStatistics, States, TopicAction, TopicSettingWizard, } from '../../classes';
import { HttpService } from '../../services/http.service';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { HttpHeaders, HttpParams } from '@angular/common/http';

export interface DashboardWizardContext {
  
  selectedCS: string;
  selectedType: number;
  Load: () => void;
}
export class DashboardActionProvider {
  constructor(public client: HttpService, public calendar: NgbCalendar) {

  }
  id: string = Guid.NewGuidStr();
  public data: any[] = [];
  public today = this.calendar.getToday();
  public visible = false;
  public edit: boolean = false;
  public siteSettingsVisible = false;  
  public resumeSiteVisible = false;  
  public isResumeAll = false;  
  public commentSystemId = ''; 
  public SelectedSite: SitesStatistics | undefined;
  fromDate: NgbDateStruct | null = null;
  public wizardContext: DashboardWizardContext | undefined;
  public get toDate(): NgbDateStruct | null {
    return this._toDate;
  }
  public set toDate(val: NgbDateStruct | null) {
    if (val != this._toDate) {
      this._toDate = val;
      this.Load();
    }

  }
  _toDate: NgbDateStruct | null = null;
  get FromDate() {
    if (this.fromDate != null)
      return new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
    return null;
  }
  get ToDate() {
    if (this.toDate != null)
      return new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
    return null;
  }
  public ClearFilter() {
    this.fromDate = null;
    this.toDate = null;
  }
  public Load() {
    this.fetch((data: any) => {
      this.data = data;
    });
  }
  public fetch(cb: any) {
    let next = this.calendar.getNext(this.today, 'm', 1);
    let from = this.FromDate != null ? this.FromDate : new Date(this.today.year, this.today.month - 1, 1);
    let todate = this.ToDate != null ? this.ToDate : new Date(next.year, next.month - 1, 1);
    this.client.get<any[]>(
      `api/commentsystem/statistics?from=${from.toISOString()}&to=${todate.toISOString()}`
    ).subscribe((res) => {
      cb(res);
    }, (error) => {
      console.log(error);
    });
  }

  public Create() {
    this.wizardContext = {
      selectedCS: '',
      selectedType: 0,    
      Load: () => { this.Load() }

    };
    this.edit = false;
    this.visible = true;
  
  }

  public EditCommentSystem(id: string, type: number) {
    this.edit = true;
    this.wizardContext = {
      selectedCS: id,
      selectedType: type,
      Load: () => { this.Load() }
    };
    this.visible = true;
  }
  public EditSite() {
    this.siteSettingsVisible = true;
  }
  public OpenResumeDialog(isResumeAll: boolean, commentSystemId: string = '') {
    this.resumeSiteVisible = true;
    this.isResumeAll = isResumeAll;
    this.commentSystemId = commentSystemId;
  }
  public PauseSite() {
    let id: string = '';
    if (this.SelectedSite != null) {
      id = this.SelectedSite.site.id;
      let that = this;
      this.client.get(
        `api/site/${id}/pause`
      ).subscribe((res) => {
        that.Load();
      }, (error) => {
        console.log(error);
      });
    }
  }
  public StartSite(startTime: string) {
    let id: string = '';
    if (this.SelectedSite != null) {
      id = this.SelectedSite.site.id;

      let params = new HttpParams().set("comment_start_time", startTime);
      
      this.client.get(
        `api/site/${id}/start`, params
      ).subscribe({
        next : (res) => {
            this.Load();
        }, 
        error: (error) => {
            console.log(error);
      }});
    }
  }
  public ResumeAllSites(startTime: string) {
      if(this.commentSystemId == '') return;
      let params = new HttpParams().set("comment_start_time", startTime);

      this.client.get(
        `api/commentsystem/${this.commentSystemId}/startall`, params
      ).subscribe({
        next : (res) => {
            this.Load();
        }, 
        error: (error) => {
            console.log(error);
      }});
  }
  public PauseAllSites(commentSystemId = '') {
    if(commentSystemId == '') return;
    this.client.get(
      `api/commentsystem/${commentSystemId}/pauseall`
    ).subscribe(
      {
      next : (res) => {
          this.Load();
      }, 
      error: (error) => {
          console.log(error);
    }});
}
  public DeleteSite() {
    let id: string = '';
    if ( this.SelectedSite != null) {
      id = this.SelectedSite.site.id;
      let that = this;
      this.client.delete(
        `api/site/${id}`
      ).subscribe((res) => {
        that.Load();
      }, (error) => {
        console.log(error);
      });
    }    
  }

  public DeleteCommentSystem(id: string) {
    let that = this;
    this.client.delete(
      `api/commentsystem/${id}`
    ).subscribe((res) => {
      that.Load();
    }, (error) => {
      console.log(error);
    });
  }
}
