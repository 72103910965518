import { Injectable, Input, EventEmitter, Output } from '@angular/core';

export interface MessageHandlerPayload {
  channel: string,
  message:any
}
export type MessageEventHandler = (event: MessageEvent) => void;
@Injectable({ providedIn: 'root' })
export class WindowMassageService {

  constructor() {
    this.register();
  }
  private messageEventHandler?: MessageEventHandler;
  public register(): void {
    window.addEventListener('message', this.getMessageEventHandler());
  }

  public unregister(): void {  
    window.removeEventListener('message', this.getMessageEventHandler());
  }
  private getMessageEventHandler(): MessageEventHandler {
    if (!this.messageEventHandler) {
      this.messageEventHandler = this.onMessageEvent.bind(this);
    }
    return this.messageEventHandler;
  }
  private readonly origin: string = window.origin || window.location.origin;
  private onMessageEvent(event: MessageEvent): void {
    if (event.origin !== this.origin) {
      return;
    }

    if (Array.isArray(event.data)) {
      event.data.forEach(this.messageHandler);
    } else {
      this.messageHandler(event.data);
    }
  }

  private async messageHandler(payload: MessageHandlerPayload): Promise<void> {
    const channelNamePrefix: string = 'ToOncommentsWeb:';
    const { channel, message }: MessageHandlerPayload = payload;
    const isValidChannel: boolean = !!channel && channel.startsWith(channelNamePrefix);
    const channelName: string = (isValidChannel && channel.replace(channelNamePrefix, '')) || '';

    if (!isValidChannel) {
      return;
    }
  
   
    switch (channelName) {
      case 'TelegramUser':
        this.telegramUserObserver.emit(payload.message);
        break;
    }
  }

  public telegramUserObserver = new EventEmitter();
}
