import { Component, Input, OnInit } from '@angular/core';
import { CSWizardSettings, TopicAction, TopicSettingWizard,  } from '../../../classes';


export class TopicSettingWizardData {
  name: string = '';
  checked: boolean = true;
  type: number = 1;
  warning: number = 1;
  block: number = 10;
}

@Component({
  selector: 'topicsettings-item',
  templateUrl: './topicsettings.item.html'
})

export class TopicSettingsItem{
  @Input()
  get data() {
    return this._value;
  }
  set data(val: any) {
    this._value = val;
  }
  private _value: any;
} 

