<div class="billingpage">
  <div class="billingpage__wrapper">
    <div class="billingpage_top">
      <div class="billinfopanel">
        <quota-input [(value)]="balance.quota"></quota-input>
        <div class="billinfopanel__cont">
          <div>
            <p class="billpanel-text">Actual cost</p>
            <p class="billinfopanel__bill">$ {{balance.actual_cost}}</p>
          </div>
        </div>


        <div class="billinfopanel__cont">
          <div>
            <p class="billpanel-text">Payment due</p>
            <p class="billinfopanel__bill">$ {{balance.payment_due}}</p>
          </div>
        </div>
      </div>

      <div class="paymentinfopanel">
        <div class="billpanel-title">
          <p class="billpanel-text">Payment information</p>
          <div class="paymentinfopanel__edit" (click)="updateSubscription()"></div>          
        </div>
        <div class="payment-card"  [ngClass]="getCardType()">
          <p>Mastercard<span>{{getCard4Number()}}</span></p>
          <p>Exp. date<span>{{getCardDate()}}</span></p>
        </div>
      </div>
    </div>  
    <p class="billingpage__title">Tariff plans </p>
    <div class="billing-tariffs">
      <div class="billing-tariff"
           *ngFor="let tariff of prices">
        <div class="billing-tariff__wrap">
          <div class="billing-tariff__info">
            <p class="billing-tariff__title">{{tariff.product_code}} <span [ngClass]="geTtariffClass(tariff)">{{geTtariffText(tariff)}}</span></p>
            <p class="billing-tariff__price">$ {{tariff.amount}}/per month </p>
          </div>
          <div class="billing-tariff__infobtn" [ngClass]="tariff.is_active?'hide':''" (click)="startSubscription(tariff.remote_id)"> </div>
        </div>
        <p class="billing-tariff__option">included {{tariff.limit}} messages </p>
        <p class="billing-tariff__option">$ {{tariff.above}} per messages above </p>
      </div>
    </div>
    <p class="billingpage__title">Payment history </p>
    <p class="billing-payment" *ngIf="!(data && data.length>0)">No transactions yet</p>
    <div class="billingtable" *ngIf="data && data.length>0">

      <div class="billingtable__header">
        <div class="billingtable__col">amount</div>
        <div class="billingtable__col">status</div>
        <div class="billingtable__col">date</div>
        <div class="billingtable__col billingtable__colright">payment method</div>
      </div>
      <div class="billingtable__row" *ngFor="let item of data">
        <div class="billingtable__col"><p>{{item.amount}}</p> </div>
        <div class="billingtable__col">
          <p [ngClass]="GetStausClass(item)">{{GetStausText(item)}}</p>
        </div>
        <div class="billingtable__col"><p>{{GetDate(item.date)}}</p></div>
        <div class="billingtable__col billingtable__colright"><p>{{item.type_card}}</p></div>
      </div>
    </div>

  </div>


</div>
<simple-dialog class="delete-dialog" [(dialog)]="ChangeTarifDialog" [(visible)]="ChangeTarifDialogVisible">
  <p>Are you sure you want to change your tariff plan?</p>
</simple-dialog>
