import { Route, Routes } from '@angular/router';
import { LoginComponent } from './views/SignIn/Login/login.component';
import { RouteNames } from './classes';
import { RegistrationComponent } from './views/SignIn/Registration/registration.component';
import { ForgotPasswordComponent } from './views/SignIn/ForgotPassword/forgotpassword.component';
import { ResetPasswordComponent } from './views/SignIn/ResetPassword/resetpassword.component';
import { ContactUsComponent } from './views/SignIn/ContactUs/contactus.component';
import { RegistrationCompletedComponent } from './views/SignIn/RegistrationCompleted/registrationcompleted.component';
import { DashboardComponent } from './views/Dashboard/dashboard.component';
import { BillingComponent } from './views/Billing/billing.component';
import { AutorizationDisqusComponent } from './views/SignIn/AutorizationDisqus/autorizationdisqus.component';

export class AppRoutes {
  static login: Routes = [
    /*  { path: '', redirectTo: `${RouteNames.Login}`, pathMatch: 'full' },*/
    {
      path: '',
      data: { title: "Login" },
      component: LoginComponent
    },
    {
      path: RouteNames.Login,
      data: { title: "Login" },
      component: LoginComponent
    },
    {
      path: RouteNames.FogotPassword,
      data: { title: "ForgotPasswordComponent" },
      component: ForgotPasswordComponent
    },
    {
      path: RouteNames.Registration,
      data: { title: "Registration" },
      component: RegistrationComponent
    },
    {
      path: `${RouteNames.RegistrationCompleted}/:email/:code`,
      data: { title: "Registration" },
      component: RegistrationCompletedComponent
    },
    {
      path: `${RouteNames.ResetPassword}/:email/:code`,
      pathMatch: 'full',
      data: { title: 'ResetPassword' },
      component: ResetPasswordComponent
    },
    {
      path: `${RouteNames.AutorizationDisqus}`,
      data: { title: 'AutorizationDisqus' },
      component: AutorizationDisqusComponent
    },   
    {
      path: `${RouteNames.ContactUs}`,
      pathMatch: 'full',
      data: { title: 'ContactUs' },
      component: ContactUsComponent
    },
  ];
  static DashboardRoutes: Routes = [
    {
      path: `${RouteNames.Dashboard}`,
      pathMatch: 'full',
      data: { title: 'Dashboard' },
      component: DashboardComponent
    },
    {
      path: `${RouteNames.Billing}`,
      pathMatch: 'full',
      data: { title: 'Billing' },
      component: BillingComponent
    },
  ];
}
