import { Component,  OnInit, ElementRef, Input, EventEmitter, Output, OnDestroy, ViewChild } from '@angular/core';

import { Observable, BehaviorSubject} from 'rxjs';
import { Helper } from '../../classes';
import { FormsModule } from '@angular/forms';
const sliderOffsets: any = {
    10: 11,
    20: 10,
    30: 8.5,
    40: 7.5,
    50: 6,
    60: 5,
    70: 4,
    80: 3,
    90: 1.5,
}
@Component({
  selector: 'range-slider',
  templateUrl: './RangeSlider.component.html'
})
export class RangeSlider{
  @Output() TopChange = new EventEmitter<any>();
  @Input()
  get Top() {
    return this._top;
  }
  set Top(val) {
    this._top = val;  
    if (this.isNormal) {
      this.topval = val;
    }
    else {
      this.bottomval = val;
    }
    this.TopChange.emit(this._top);
  }
  private _top: number = 10;

  @Output() BottomChange = new EventEmitter<any>();
  @Input() 
  get Bottom() {
    return this._bottom;
  }
  set Bottom(val) {
    this._bottom = val;  
    if (this.isNormal) {
      this.bottomval = val;
    }
    else {
      this.topval = val;
    }
    this.BottomChange.emit(this._bottom);
  }
  private _bottom: number = 0;

  get isNormal() {
    return this._topval>=this._bottomval;
  }
  get bottomval() {
    return this._bottomval;
  }
  set bottomval(val) {
    if (val != this._bottomval) {
      this._bottomval = val;
      if (this.isNormal) {
        this.Bottom = val;
        if (this._topval == val)
        this.Top = this._topval;
      }
      else {
        this.Top = val;
        if (this._topval == val)
        this.Bottom = this._topval;
      }
    }
   
  }
  private _bottomval: number = 0;

  get topval() {
    return this._topval;
  }
  set topval(val) {
    if (val != this._topval) {
      this._topval = val;
      if (this.isNormal) {
        this.Top = val;
        if (this._bottomval==val)
        this.Bottom = this._bottomval;
      }
      else {
        this.Bottom = val;
        if (this._bottomval == val)
        this.Top = this._bottomval;
      }
    }    
  }
  private _topval: number = 10;

  constructor() { }

  calcWidth(value: number) {
    const percent = value*10;
    return `calc(${percent}% + ${(percent < 100 && percent > 0) ? sliderOffsets[percent] || 0 : 0}px)`
  }
}


