//inner
import { Component,  OnInit,  ChangeDetectorRef,  EventEmitter, Input, Output, ElementRef } from '@angular/core';
import { Helper } from '../classes';

@Component({
  selector: 'dynamic-html',
  template: '<div [innerHTML]="dynamicHTML"></div>'
})
export class DynamicHtml {
  constructor(private el: ElementRef) {
  }

  @Input()
  set link(val: string) {
    if (this._link != val && Helper.NotEmpty(val)) {
      this._link = val;
      this.Load();
    }
  }
  get link(): string {
    return this._link;
  }
  _link: string = '';

  dynamicHTML: string = '';
  private Load() {    
    var xhr = new XMLHttpRequest();
    let that = this;
    xhr.onreadystatechange = function (e) {
      if (xhr.readyState == 4 && xhr.status == 200) {
        that.dynamicHTML = xhr.responseText;
      }
    }

    xhr.open("GET", that.link, true);
    xhr.setRequestHeader('Content-type', 'text/html');
    xhr.send();
  }

}
