<div class="loginform">
  <div>
    <div class="loginpanel" *ngIf="!loginservererror">
      <div class="loginpanel__img"></div>
      <div *ngIf="!wasSending">
        <p class="loginpanel__title">Forgot password?</p>
        <p class="loginpanel__subtitle marginb32">Please enter your email address. We will send you a link to reset your password.</p>
        <div class="input-label-block email-input marginb10">
          <div class="input-label-wrapper">
            <p>E-mail</p>
          </div>
          <v-input [model]="EmailModel"
                   [(value)]="Email"
                   [InputPlaceholder]="EmailPlaceHolder"
                   [Mask]="EmailMask"
                   [ShowMaskTyped]="false"> </v-input>
        </div>
        <p class="ok-button button-green marginb32" (click)="ForgotEmail()">Continue</p>
      </div>
      <div *ngIf="wasSending">
        <p class="loginpanel__title">Success!</p>
        <br />
        <p class="loginpanel__subtitle marginb32">Please check your email. An email has been sent to you with a link to change your password.</p>
      </div>
      
      <p class="singup-link-wrapper"> <a [routerLink]="'/login'" class="green-link singup-link">Sign in</a></p>
    </div>

    <div class="login-servererror loginpanel" *ngIf="loginservererror">
      <div style="height:56px;">
        <h3 class="loginpanel__title display-block">Error</h3>
      </div>
      <div class="login-servererror-wrap">
        <p class="text1424">{{loginMessage}}</p>
      </div>
      <p class="login-tryagain" (click)="loginservererror=false">Try again</p>

    </div>
  </div>
</div>
