import { Component,  OnInit, ElementRef, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, AsyncValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { Observable, BehaviorSubject} from 'rxjs';
import { Helper } from '../../classes';
import { VInputModel } from '..';

@Component({
  selector: 'vselect-box',
  templateUrl: './ValidateionSelectBox.component.html'
})

export class ValidateionSelectBox implements OnInit, OnDestroy {
  @Output()
  public openChange = new EventEmitter<boolean>();
  @Input()
  set open(val) {
    if (this._open == val) return;
    this._open = val;
    this.openChange.emit(this._open );
  }
  get open() {
    return this._open;
  }
  private _open = false;
  @Input()
  readonly: boolean = true;
  @Output()
  public valueChange = new EventEmitter<any>();
  @Input() 
  set value(val:any) {
   // if (Helper.Compare(this._value, val)) return;
    this._value = val;
    this.valueChange.emit(this._value);
    if (val != null) {
      let name = '';
      if (this.keyExp != null) {
        let values = this.items.filter(i => i[this.keyExp] == val);
        name = values.length > 0 ?  values[0][this.displayExp] : '';
      }
      else {
        name = val[this.displayExp];
      }
      this.data.FormControl.setValue(name);
    }
    else
      this.data.FormControl.setValue('');
     
  }
  get value() {
    return this._value;
  }
  
  private _value: any;
  public setvalue(val:any) {
    if (this.keyExp != null)
      this.value = val[this.keyExp];
    else
      this.value = val;
  } 

  @Input() 
  set data(val) {
    if (val == null) return;
    this._model = val;
  }
  get data() {
    return this._model;
  }
  private _model = VInputModel.Defualt(); 
  @Input() 
  set label(val) {
    this.data.Label = val;
  }
  get label() {
    return this.data.Label;
  }
  public get errors() {
    return this.data.errors;
  }

  public Validate() {
    this.data.Validate();
  }
  public hasError(key:any) {
    return this.data.FormControl.hasError(key);
  }
  public get Matcher() {
    return this.data.Matcher;
  }
  public get isdefault() {
    return this.data.isdefault;
  }
  public get FormControl() {
    return this.data.FormControl;
  }
  getdisplayExp(item: any) {
    if (item != null && item[this.displayExp] != null)
      return item[this.displayExp]
    return '';
  }
  @Input() 
  set displayExp(val) {
    this._displayExp = val;
  }
  get displayExp() {
    return this._displayExp;
  }
  private _displayExp: any = 'Name';
  @Input()  
  set keyExp(val) {
    this._keyExp = val;
  }
  get keyExp() {
    return this._keyExp;
  }
  private _keyExp: any;
  @Input()  
  set items(val) {
    this._items = val;
  }
  get items() {
    return this._items;
  }
  private _items: any[]=[];
  constructor(private elRef: ElementRef) { }
    
  closehandler(e:any) {
    let root = this.elRef.nativeElement;
    if (!root.contains(e.target)) {
      this.open = false;
    }
  }
  ngOnInit(): void {
    window.addEventListener('click', this.closehandler.bind(this));
  }
  ngOnDestroy() {
    window.removeEventListener('click', this.closehandler);
  }  
}
