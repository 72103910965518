<icon-dropdown icon="row-actions-btn" dropdownclass="rowactionslist" [(open)]="open">
  <div class="rowactionslist__panel">
    <p *ngFor="let action of actions"
       (click)="action.click()"
       [ngClass]="action.Visible!()? '':'hide'"
       >
      {{action.name}}
    </p>
  </div>
</icon-dropdown>
<!--[ngClass]="action.Visible!()? '':'hide'"-->
