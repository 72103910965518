import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, Validators } from '@angular/forms';
import { Customvalidators, Guid, IUserCred, PasswordValidator, States } from '../../../classes';
import { Helper, RouteNames } from '../../../classes';
import { Dialog, VInputModel } from '../../../components';
import { HttpService } from '../../../services/http.service';

class SignUpModel implements IUserCred {
  public Name: string='';
  public Surname: string = '';
  public MiddleName: string = '';
  public Email: string = '';
  public Password: string = '';
  public LoanOfficeId: string = Guid.NewGuidStr();
  public Phone: string = '';
  public CompanyName: string = '';
  public NMLSId: string = '';
  public Street: string = '';
  public City: string = '';
  public State: string = '';
  public ZipCode: string = '';
  public ConfirmPassword: string = '';
}

@Component({
  templateUrl: './registrationcompleted.component.html'
})
export class RegistrationCompletedComponent implements OnInit{
 
  static Path: "Registration"
  public Data: SignUpModel = new SignUpModel();
  public Authorized: boolean = false;
  public resenderror = false;
  public resendMessage: any = "";
  public IsRegistration = false;

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['email'] && params['code']) {
        const email = params['email'];
        const code = params['code'];
        this.client.AccountVerify(email, code).subscribe(
          (res) => {
          this.IsRegistration = true;
          },
          (error: any) => {
          this.resendMessage = Helper.GetErrorMessage(error);
          this.resenderror = true;
        });
      }
    });
    
  }
 

  constructor(public client: HttpService, private route: ActivatedRoute) {
    this.Authorized = HttpService.Authorized;
 
  } 
} 
