import { Component, Injectable, Input, Output, OnChanges, OnInit, EventEmitter, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit, ElementRef } from '@angular/core';

import { HttpParams } from '@angular/common/http';
import { NgbDateParserFormatter, NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '../dateformat';


@Component({
  selector: 'period-selector',
  templateUrl: 'PeriodSelector.template.html',
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})

export class PeriodSelectorComponent implements OnInit {
  hoveredDate: NgbDate | null = null;
  openedFrom: boolean = false;

  @Output()
  public fromDateChange = new EventEmitter<any>();
  @Output()
  public toDateChange = new EventEmitter<any>();

 

  @Input()
  set fromDate(val: NgbDateStruct | null) {
    if (val != this._fromDate) {
      this._fromDate = val;
      this.fromDateChange.emit(this._fromDate);
    }
  }
  get fromDate() {
    return this._fromDate;
  }
 


  @Input()
  set toDate(val: NgbDateStruct | null) {
    if (val != this._toDate) {
      this._toDate = val;
      this.toDateChange.emit(this._toDate);
    }
  }
  get toDate() {
    return this._toDate;
  }
 

  @Input() maxDate: NgbDateStruct;

  public ClearPeiod() {
    this.fromDate = null;
    this.toDate = null;
  }

  public get startDate() {
    return this.fromDate != null ? this.fromDate : this._startDate;
  }
  @Output() calendarDateChanged = new EventEmitter<any>();
  
  _startDate: NgbDateStruct;
  _fromDate: NgbDateStruct | null;
  _toDate: NgbDateStruct | null;

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    this.maxDate = calendar.getToday();
    this._startDate = calendar.getPrev(calendar.getToday(), 'm', 1);
    this._fromDate = calendar.getPrev(calendar.getToday(), 'm', 1);
    this._toDate = calendar.getToday();
  }

  public get isPeriodSelected() {
    return this.fromDate != null && this.toDate != null;
  }

  onDateSelection(date: NgbDate, datepicker: any) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.fromDate && this.toDate && this.dateChangeEmit();
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }
   
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  dateChangeEmit() {
    this.calendarDateChanged.emit();
  }

  ngOnChanges() {
  }

  ngOnInit() {
  }

}

