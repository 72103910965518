//inner
import { Component, OnInit, ChangeDetectorRef, EventEmitter, Input, Output } from '@angular/core';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from '../../services/http.service';


@Component({
    selector: 'calendar',
    templateUrl: './Calendar.component.html',
    styleUrls: ['./Calendar.component.scss']
})

export class CalendarComponent {
    maxDate: NgbDateStruct;

    constructor(private calendar: NgbCalendar, public client: HttpService) {
        this.maxDate = this.calendar.getToday();
    }
    calendarModel!: NgbDateStruct;

    closeCalendar(event: any, datePicker: any) {
        if (!event.target.closest('.calendar-input') &&
            !event.target.closest('ngb-datepicker')) {
            datePicker.close();
        }
    }
    
    dateSelect() {
        const { year, month, day } = this.calendarModel;
        this.onDateSelect.emit(new Date(`${year} ${month} ${day} UTC`).toISOString());
    }

    @Output() onDateSelect = new EventEmitter<string>();
}
