<div class="loginform ">
  <div>
    <div class="loginpanel registration-succes" *ngIf="!resenderror">
      <div class="loginpanel__img"></div>
      <p class="loginpanel__title marginb10">Thank you for Registering!</p>     
      <div class="registration-results marginb32">
        <div class="icon-sber">
          <p>Create your account</p>
        </div>
        <div class="icon-mailok active">
          <p>Email confirmation</p>
        </div>
        <div class="icon-jestgood active"  [ngClass]="IsRegistration? 'active':''">
          <p>Validate account</p>
        </div>
      </div>
      <p class=" ok-button  button-blue marginb32" [routerLink]="'/login'">Sign in</p>
    </div>
    <div class="login-servererror loginpanel" *ngIf="resenderror">
      <div style="height:56px;">
        <h3 class="loginpanel__title display-block">Error</h3>
      </div>
      <div class="login-servererror-wrap">
        <p class="text1424">{{resendMessage}}</p>
      </div>
      <p class="login-tryagain" (click)="resenderror=false">Try again</p>

    </div>
  </div>
</div>
