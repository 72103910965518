import { Component, OnInit } from '@angular/core';
import {  ActivatedRoute } from '@angular/router';
import { VInputModel, Wizard } from '../../components';
import { CSWizardSettings, CommentSystemForCreationDto, Customvalidators, Guid, Helper, SiteForCreationDto, States, TopicAction, TopicSettingWizard,  } from '../../classes';
import { HttpService } from '../../services/http.service';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DashboardActionProvider } from './DashboardActionProvider';

@Component({
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit{
  actionProvider: any;
 
  constructor(public client: HttpService, private calendar: NgbCalendar) {
    this.actionProvider = new DashboardActionProvider(client, calendar);
  }
  ngOnInit() {
    this.actionProvider.Load();
  }  


  commentSystem: CommentSystemForCreationDto = new CommentSystemForCreationDto();
  public GetSiteName(site: { name: string, name_id: string }) {
    return Helper.NotEmpty(site.name) ? site.name : site.name_id;
  }
} 

