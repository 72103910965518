import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { RouteNames, Helper, UserModel, TypesDto, CommonTypes} from '../classes';
import { filter, map, catchError } from 'rxjs/operators';
import { BehaviorSubject, of, throwError, Observable, EMPTY } from 'rxjs';

class AuthorizeWatcher {
  syncauth() {
    HttpService.Token = localStorage["auth_token"] || "";
    const Authorized = Helper.NotEmpty(HttpService.Token);
    if (Authorized != HttpService.Authorized)
      HttpService.SetAuthorized(Authorized);
  }
  constructor() {
    setInterval(this.syncauth, 1000);
  }
}

@Injectable({ providedIn: 'root' })
export class HttpService {
  static Token: any = localStorage["auth_token"] || "";
  static Authorized: boolean = Helper.NotEmpty(HttpService.Token);
  static authorizedObserver = new BehaviorSubject<boolean>(HttpService.Authorized);
 
  static router: Router;
  static activationDone: boolean = false;
  static SetAuthorized(value: boolean) {
    HttpService.Authorized = value;
    HttpService.authorizedObserver.next(HttpService.Authorized);
    this.Authorized = HttpService.Authorized;
  }

  constructor(private notificator: NotificationService, private router: Router, private http: HttpClient) {
    HttpService.router = router;
    HttpService.Token = localStorage["auth_token"];
  }

  navigate(address: any) {
    this.router.navigate(address);
  }

  public static GetToken() {
    return new Promise<any>((resolve, reject) => {
      HttpService.authorizedObserver.subscribe(Authorized => {
        if (Authorized) {
          resolve(HttpService.Token);
        }
      });
    });
  }
  static userObserver = new BehaviorSubject<any>(null);
  static typeObserver = new BehaviorSubject<any>(null);
  private get currentUser(): UserModel | undefined {
    return Helper.GetFromStorage('currentUser');
  }
  public GetCurrentUser() {
    return new Promise<UserModel>((resolve, reject) => {
       this.get<UserModel>('api/user').subscribe((u) => {
        resolve(u);
      });
    });    
  }

  private get commonTypes(): any | undefined {
    return Helper.GetFromStorage('commontypes');
  }
  public GetTypes() {
    return new Promise<CommonTypes>((resolve, reject) => {
      let commonTypes: TypesDto = this.commonTypes;
      if (commonTypes != null)
        resolve(new CommonTypes( commonTypes));
      else {
        this.getTypes();
        HttpService.typeObserver.subscribe((u) => {
          if (u != null) {
            resolve(new CommonTypes(u));
          }
        })
      }
    }); 
  }
  static isTypesFetch = false;
  private getTypes() {
    if (!HttpService.isTypesFetch) {
      HttpService.isTypesFetch = true;
      this.get<UserModel>('api/types').subscribe((u) => {
        Helper.SaveToStorage("commontypes", u);
        HttpService.typeObserver.next(u);
      });
    }
  }

  public Logout() {
    document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    localStorage.removeItem("auth_token");
    localStorage.removeItem("device_token");
    localStorage.removeItem("token_date");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("commontypes");
    
    HttpService.Authorized = false;
    HttpService.Token = "";
    HttpService.SetAuthorized(false);
  }

 
 

  setToken(authtoken: string, devicetoken: string | null = null) {
    HttpService.Token = authtoken;   
    localStorage["auth_token"] = authtoken;
    if (devicetoken != null) {
      localStorage["device_token"] = authtoken;
      HttpService.SetAuthorized(true);
    }     
    else
      localStorage.removeItem("device_token");
      HttpService.SetAuthorized(true);
  }

  public login(data: any, remmber: boolean) {
    let that = this;
    let obs = new Observable<string>(sub => {
      that.http.post<any>(`${HttpService.baseadr}api/Auth/login`, data).subscribe(
        res => {
          let token = res['token'];
          if (Helper.NotEmpty( token )) {
            Helper.ClearStorage();
            sub.next(res);
          }
          else {
            sub.error('Empty token');
          }
        },
        error => { sub.error(error);  }
      );
    });
    return obs;
  }

  public loginGoogle(data: any, remmber: boolean) {
    let that = this;
    let obs = new Observable<string>(sub => {
      that.http.post<any>(`${HttpService.baseadr}api/Auth/GoogleExternalLogin`, data).subscribe(
        res => {
          let token = res['token'];
          if (Helper.NotEmpty(token)) {
            Helper.ClearStorage();
            sub.next(res);
          }
          else {
            sub.error('Empty token');
          }
        },
        error => { sub.error(error); }
      );
    });
    return obs;
  }

  public pjsGet(link: string) {
    let obs = new Observable<any>(sub => {      
      const req = new XMLHttpRequest();
      req.open('GET', link);
      req.onload = () => {
        if (req.status != 200)
          sub.error(req.response);
        else
          sub.next(req.response);
      };
      req.send();
    });
    return obs;
  }

  public pjsPost(link: string,data:any) {
    let obs = new Observable<any>(sub => {
      const req = new XMLHttpRequest();
      req.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      req.open('POST', link);
      req.onload = () => {
        if (req.status != 200)
          sub.error(req.response);
        else
          sub.next(req.response);
      };
      req.send(data);
    });
    return obs;
  }
  public AccountVerify(email: string, code: string) {
    const link = `${HttpService.baseadr}api/auth/validate/${email}/${code}`;
    return this.pjsGet(link);
  }
  public CreteAccount(data: any, fail: (error: any) => void) {
    let that = this;
    let obs = new Observable<any>(sub => {
      that.http.post<any>(`${HttpService.baseadr}api/auth/register`, data).subscribe(
        res => {
          sub.next(res);
        },
        error => { fail(error); }
      );
    })
    return obs;
  }
  public ForgotPassword(data: any) {
    let that = this;
    let options: any = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    let obs = new Observable<any>(sub => {
      that.http.post<any>(`${HttpService.baseadr}api/auth/forgot`, Helper.stringify(data), options).subscribe(
        res => {
          sub.next(res);
        },
        error => { sub.error(error); }
      );
    })
    return obs;
  }

  public ResetPassword(data: any) {
    let that = this;
    let options: any = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    let obs = new Observable<any>(sub => {
      that.http.post<any>(`${HttpService.baseadr}api/auth/reset`, Helper.stringify(data), options).subscribe(
        res => {
          sub.next(res);
        },
        error => { sub.error(error); }
      );
    })
    return obs;
  }

  public GetPaymentTransactionId() {
    let obs = new Observable<any>(sub => {
      HttpService.GetToken().then(Token => {
          const link = HttpService.baseadr + 'api/payment/update';
        const req = new XMLHttpRequest();
        req.open('GET', link);
        req.setRequestHeader('Authorization', 'Bearer ' + Token);
        req.setRequestHeader('Content-Type', 'text/plain; charset=utf-8 ');
        req.onload = () => {          
          sub.next(req.response);
        };
        req.send();
      });
    });
    return obs;
  }


  getHeader(Token: string) {
    let headers: any = {
      'Authorization': 'Bearer ' + Token,
      'Content-Type': 'application/json'
    }
    let dt = localStorage["device_token"]
    if (dt != null)
      headers['device_tokene'] = dt;
    return headers;
  }
  public TotpValidate(Token: string, code: string) {
    let that = this;
     let options:any = {
       headers: that.getHeader(Token)
    };
   return that.http.post<any>(`${HttpService.baseadr}Core/Authentication/totp_validate`, Helper.stringify(code), options);
  }

  static baseadr: any = Helper.webApiUrl();
  private notifyError(mes: string, path: string, error:any) {
    this.notificator.addError(mes, path, error);
    if (error.status == 401) {
      HttpService.SetAuthorized(false);
    }
  }
  public get<T>(path: any, data? : HttpParams, depth = 10) {
    let that = this;
    let obs = new Observable<T>(sub => {
      HttpService.GetToken().then(Token => {
        let options = {
          headers: that.getHeader(Token),
          params: data
        };
        const link = HttpService.baseadr + path;
        let subs = that.http.get<T>(link, options).subscribe(
          data => {
            Helper.deserializeJSONRefs(data, depth);
            sub.next(data);
          },
          error => {
            let mes = error.statusText;
            that.notifyError(mes, path, error);
            sub.error(error);
          },
          () => {
            sub.complete();
          }
        )
      });
    });
    return obs;
  }

  public getHTTPExactly(path: any, data?: HttpParams, headersoption?: { [id: string]: any }, depth=10) {
    let that = this;
    let obs = new Observable<any>(sub => {
      HttpService.GetToken().then(Token => {
        let options = {
          headers: that.getHeader(Token),
          params: data
        };
        if (headersoption != null) {
          Object.keys(headersoption).forEach(function (key) {
            options.headers[key] = headersoption[key];
          });
        }
        const link = HttpService.baseadr + path;
        const subs = that.http.get(link, options).subscribe(
          res => {
           // Helper.deserializeJSONRefs(res, depth);
            sub.next(res);
          },
          error => {
            let mes = error.statusText;
            that.notifyError(mes, path, error);
            return EMPTY;
          },
          () => {
            sub.complete();
          }
        )
      });
    });
    return obs;
  }

  public post<T>(path: any, data?: any, headersoption?: { [id: string]: any } ) {
    let that = this;
    let param: any;
    if (typeof data === 'string')
      param = data;
    else
      param = Helper.stringify(data);
    let obs = new Observable<T>(sub => {
      HttpService.GetToken().then(Token => {
        let options:any = {
          headers: that.getHeader(Token)
        };
        if (headersoption != null) {
          Object.keys(headersoption).forEach(function (key) {
            options.headers[key] = headersoption[key];
          });
        }
        const link = HttpService.baseadr + path;
        const subs = this.http.post<T>(link, param, options).subscribe((res: any) => {
            let data: any = res;
            if (res && res.body != null) {
              data = res.body;
            }
            Helper.deserializeJSONRefs(data);
            sub.next(data);
          },
          error => {
            let mes = error.statusText;
            that.notifyError(mes, path, error);
            sub.error(error);
          },
          () => {
            sub.complete();
          }
        );
      });
    });
    return obs;
  }

  public postjson<T>(path: any, data: any) {
    return this.post<T>(path, data, { 'Content-Type': 'application/json-patch+json' });
  }

  public put<T>(path: any, data?: any, headersoption?: { [id: string]: any }) {
    let that = this;
    let param: any;
    if (typeof data === 'string')
      param = data;
    else
      param = Helper.stringify(data);
    let obs = new Observable<T>(sub => {
      HttpService.GetToken().then(Token => {
        let options:any = {
          headers: that.getHeader(Token)
        };
        if (headersoption != null) {
          Object.keys(headersoption).forEach(function (key) {
            options.headers[key] = headersoption[key];
          });
        }
        const link = HttpService.baseadr + path;      
        return this.http.put<T>(link, param, options).subscribe(
            (res:any) => {
            if (res.body != null) {
              let data = res.body;
              Helper.deserializeJSONRefs(data);
              sub.next(data);
            }
            else
              sub.next(undefined);
            },
            error => {
              let mes = error.statusText;
              let dis = error.responseText;
              that.notifyError(mes, path, dis);
              sub.error(error);
            },
            () => {
              sub.complete();
            }
          )
        })
    })
    return obs;
  }

  public delete<T>(path: any, headersoption?: { [id: string]: any }, globalOptions: any = null){
    let that = this;
    let obs = new Observable<T>(sub => {
      HttpService.GetToken().then(Token => {
        let options :any= {
          headers: that.getHeader(Token)
        };
        
        if (headersoption != null) {
          Object.keys(headersoption).forEach(function (key) {
            options.headers[key] = headersoption[key];
          });
        }
        if (globalOptions) {
          Object.keys(globalOptions).forEach(function (key) {
            options[key] = globalOptions[key];
          });
        }
        var link = HttpService.baseadr + path;
        this.http.delete<T>(link, options).subscribe(
          (res:any) => {
            if (res != null && res.body != null) {
              let data = res.body;
              Helper.deserializeJSONRefs(data);
              sub.next(data);
            }
            else
              sub.next(undefined);
          },
          error => {
            let mes = error.statusText;
            let dis = error.responseText;
            that.notifyError(mes, path, dis);
            sub.error(error);
          },
          () => {
            sub.complete();
          }
        )
      })
    });
    return obs;
  }

  public patch<T>(path: any, data: any, headersoption?: { [id: string]: any }) {
    let that = this;
    let obs = new Observable<T>(sub => {
      HttpService.GetToken().then(Token => {
        let options = {
          headers: that.getHeader(Token)
        };
        var link = HttpService.baseadr + path;
        this.http.patch<T>(link, data, options).subscribe(
          data => {
            sub.next(data);
          },
          error => {
            let mes = error.statusText;
            let dis = error.responseText;
            that.notifyError(mes, path, dis);
            sub.error(error);
          },
          () => {
            sub.complete();
          }
        )
      })
    });
    return obs;
  }    
}
