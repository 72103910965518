import { Component,  OnInit, ElementRef, Input, EventEmitter, Output, OnDestroy } from '@angular/core';

import { Observable, BehaviorSubject} from 'rxjs';
import { Helper } from '../../classes';
import { MultySelectOptions } from '../interfaces';


@Component({
  selector: 'multy-selectbox',
  templateUrl: './MultySelectBox.component.html'
})

export class MultySelectBox implements OnInit, OnDestroy {

  @Output()
  public valueChange = new EventEmitter<any>();
 
  @Input()
  set value(val) {
    if (val != this._value) {
      this._value = val;
      if (val.length > 0 && val[0] != undefined)
        this._WithoutAll = false;
      else {
        this.Items.forEach((item: any) => { item.check = false; });
      }
      this.valueChange.emit(this._value);
    }
  }
  get value() {
    return this._value;
  }
  private _value: any[] = [];

  _WithoutAll = false;
  get WithoutAll() {
    return this._WithoutAll;
  }
  set WithoutAll(val) {
    if (this._WithoutAll == val) return;
    this._WithoutAll = val;
    this.Items.forEach((item: any) => { item.check = false; });
    this.value = val ? [undefined] : [];
  }

  @Input()
  public options: MultySelectOptions = new MultySelectOptions();
  public get ValueCount() {
    return this.Items.filter(item => item.check == true).length;
  }
  @Output()
  public itemsChange = new EventEmitter<any[]>();
  @Input()
  get items() {
    return this.Items;
  }
  set items(val) {
    if (this.Items == val) return;
    this.Items = val;
    this.itemsChange.emit(this.Items);
  }

  public Items: any[] = [];
  @Input()
  public displayExp: string = 'Name';
  public GetTitle(item: any) {
    return item[this.displayExp];
  }

  public ItemsCheckChange() {
    this.value = this.items.filter((item) => item.check);
  }
  @Output()
  public openChange = new EventEmitter<boolean>();
  @Input()
  get open() {
    return this._open;
  }
  set open(val) {
    if (this._open == val) return;
    this._open = val;
    if (this.open)
      this.ItemsCheckChange();
    this.openChange.emit(this._open );
  }
  private _open = false;


  @Input()
  get label() {
    return this.options.Label;
  }
  set label(val) {
    this.options.Label = val;
  }

  constructor(private elRef: ElementRef) { }
  closehandler(e:any) {
    let root = this.elRef.nativeElement;
    if (!root.contains(e.target)) {
      this.open = false;
    }
  }
  ngOnInit(): void {
    window.addEventListener('click', this.closehandler.bind(this));
  }
  ngOnDestroy() {
    window.removeEventListener('click', this.closehandler);
  }

  public All() {
    this.Items.forEach((item: any) => { item.check = true; });
    this.value = this.Items;
  }

  public None() {
    this.Items.forEach((item: any) => { item.check = false; });
    this.value = [];
  }
}
