import { NgModule, Component } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MaterialAllModule } from './material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonComponentsModule } from './components';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LoginComponent } from './views/SignIn/Login/login.component'
import { RegistrationComponent } from './views/SignIn/Registration/registration.component'
import { ForgotPasswordComponent } from './views/SignIn/ForgotPassword/forgotpassword.component';
import { ResetPasswordComponent } from './views/SignIn/ResetPassword/resetpassword.component';
import { ContactUsComponent } from './views/SignIn/ContactUs/contactus.component';
import { ClickOutsideDirective } from './clickoutside';
import { RegistrationCompletedComponent } from './views/SignIn/RegistrationCompleted/registrationcompleted.component';
import { DashboardComponent } from './views/Dashboard/dashboard.component';
import { NotificationComponent } from './views/Layout/Notification.component';
import { UserInfoComponent } from './views/Layout/UserInfo.component';
import { BillingComponent } from './views/Billing/billing.component';
import { DashboardTableComponent } from './views/Dashboard/dashboardtable.component';
import { RowActionsComponent } from './views/Dashboard/RowActions.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AutorizationDisqusComponent } from './views/SignIn/AutorizationDisqus/autorizationdisqus.component';
import { QuotaInputComponent } from './views/Billing/quotainput.component';
import { CommentSystemWizard } from './views/Dashboard/Wizard/commentsystem.wizard';
import { TopicSettingsItem } from './views/Dashboard/Wizard/topicsettings.item';
import { SiteSettingsDialog } from './views/Dashboard/Wizard/sitesettings.dialog';
import { ResumeSiteDialog } from './views/Dashboard/Wizard/ResumeSiteDialog/resumesite.dialog';
import { TelegramWigetComponent } from './views/Dashboard/Wizard/telegramwiget.component';
import { AutorizationGoogleWiget } from './views/SignIn/AutorizationGoogleWiget/autorizationgoogle.wiget';
import { DebtInfoComponent } from './views/Layout/DebtInfo.component';
import { CalendarComponent } from './components/Calendar/Calendar.component';

@NgModule({
  declarations: [
    LoginComponent,
    RegistrationComponent,
    RegistrationCompletedComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AutorizationDisqusComponent,
    AutorizationGoogleWiget,
    ContactUsComponent,
    BillingComponent,
    QuotaInputComponent,
    DashboardComponent,
    DashboardTableComponent,
    CommentSystemWizard,
    TelegramWigetComponent,
    SiteSettingsDialog,
    ResumeSiteDialog,
    TopicSettingsItem,
    NotificationComponent,
    UserInfoComponent,
    DebtInfoComponent,
    CalendarComponent,
    RowActionsComponent,
    AppComponent,
    ClickOutsideDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxDatatableModule,
    NgbModule,
    MaterialAllModule,
    MatFormFieldModule,
    CommonComponentsModule
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    // { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
