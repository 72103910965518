import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, Validators } from '@angular/forms';
import { VInputModel } from '../../../components';
import { HttpService } from '../../../services/http.service';
import { Customvalidators, Helper, RouteNames } from '../../../classes';


@Component({
  templateUrl: './forgotpassword.component.html'
})
export class ForgotPasswordComponent implements OnInit{
  public EmailMask = Helper.EmailMask;
  public EmailPlaceHolder = 'yourmail@gmail.com';
  public Email = 'winterswind@amil.ru';
  public loginMessage = '';
  public loginservererror = false;
  public wasSending = false;
  public EmailModel = new VInputModel({
    Label: 'yourmail@gmail.com',
    errors: [
      { key: 'required', value: 'Email is required' },
      { key: 'Email', value: 'Please enter a valid Email' }
    ],
    Validators: [Customvalidators.EmailValidator()],
  });
  ngOnInit() {
   
  }

  constructor(public client: HttpService) {   
  }
  public ForgotEmail() {
    if (this.EmailModel.Validate()) {
      this.client.ForgotPassword({ email: this.Email }).subscribe(
        (r) => {
          this.wasSending=true;
        console.log(r);
        //this.client.navigate([RouteNames.Login]);
      }      , (error) => {
          console.log(error);
          this.loginservererror = true;
          this.loginMessage = error.error.detail;
        });
    }
  }
}
