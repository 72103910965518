<div class="loginform" >
  <div>
    <div class="loginpanel">
      <div class="loginpanel__img"></div>
      <p class="loginpanel__title marginb32">Contact us</p>

      <div class="input-label-block">
        <div class="input-label-wrapper">
          <p>First name<span>*</span></p>
        </div>
        <v-input [model]="NameModel" [(value)]="Data.Name"> </v-input>
      </div>
      <div class="input-label-block">
        <div class="input-label-wrapper">
          <p>Last name<span>*</span></p>
        </div>
        <v-input [model]="SurnameModel" [(value)]="Data.Surname"> </v-input>
      </div>
      <div class="input-label-block email-input">
        <div class="input-label-wrapper">
          <p>Email<span>*</span></p>
        </div>
        <v-input [model]="EmailModel"
                 [(value)]="Data.Email"
                 [InputPlaceholder]="EmailPlaceHolder"
                 [Mask]="EmailMask"
                 [ShowMaskTyped]="false"> </v-input>
      </div>
      <div class="input-label-block">
        <div class="input-label-wrapper">
          <p>Phone<span>*</span></p>
        </div>
        <v-input class="phone-input"
                 [model]="PhoneModel"
                 [(value)]="Data.Phone"
                 [Mask]="PhoneMask">
        </v-input>
      </div>
      <div class="input-label-block">
        <div class="input-label-wrapper">
          <p>Massege<span>*</span></p>
        </div>
        <v-textarea [model]="MessageModel"  [(value)]="Data.Message"></v-textarea>      
      </div>
      <p class=" ok-button  button-blue marginb32" (click)="ContactUs()">Send</p>
      <p class="singup-link-wrapper">Don’t have an account?  <a [routerLink]="'/registration'" class="green-link singup-link">Sign up</a></p>
    </div>
  </div>
</div>
