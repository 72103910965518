import { Component,  OnInit, ElementRef, Input, EventEmitter, Output } from '@angular/core';

import { Observable, BehaviorSubject} from 'rxjs';
import { Helper } from '../../classes';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'monthnavigator',
  templateUrl: './MonthNavigator.component.html'
})

export class MonthNavigator {
  @Output()
  public fromDateChange = new EventEmitter<any>();
  @Output()
  public toDateChange = new EventEmitter<any>();



  @Input()
  set fromDate(val: NgbDateStruct | null) {
    if (val != this._fromDate) {
      this._fromDate = val;
      this.fromDateChange.emit(this._fromDate);
    }
  }
  get fromDate() {
    return this._fromDate;
  }

  @Input()
  set toDate(val: NgbDateStruct | null) {
    if (val != this._toDate) {
      this._toDate = val;
      this.toDateChange.emit(this._toDate);
    }
  }
  get toDate() {
    return this._toDate;
  }

  public ClearPeiod() {
    this.fromDate = null;
    this.toDate = null;
  }

  public get startDate() {
    return this.fromDate != null ? this.fromDate : this._startDate;
  }
  @Output() calendarDateChanged = new EventEmitter<any>();

  _startDate: NgbDateStruct;
  _fromDate: NgbDateStruct | null;
  _toDate: NgbDateStruct | null;

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    let today = calendar.getToday();
    let startDate = new NgbDate(today.year, today.month, 1);
    this._startDate = startDate;
    this._fromDate = startDate;
    this._toDate = calendar.getNext(startDate, 'm', 1);
  }

  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  public get month() {
    let fromDate = this.startDate;
    return `${this.months[fromDate.month-1]} ${fromDate.year}`;
  } 
 
  public Plus() {
    let startDate = this.startDate;
    let fromDate = new NgbDate(startDate.year, startDate.month, 1);
    this.fromDate = this.calendar.getNext(fromDate, 'm', 1);
    this.toDate = this.calendar.getNext(fromDate, 'm', 2);
  }
  public Minus() {
    let startDate = this.startDate;
    let fromDate = new NgbDate(startDate.year, startDate.month, 1);
    this.fromDate = this.calendar.getPrev(fromDate, 'm', 1);
    this.toDate = this.calendar.getPrev(fromDate, 'm', 2);
      
  }
}
