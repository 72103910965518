<div class="loginform">
  <div>
    <div class="loginpanel" *ngIf="!resenderror">
      {{code}}
      <p class="text1424">{{id}}</p>
    </div>

    <div class="login-servererror loginpanel" *ngIf="resenderror">
      <div style="height:56px;">
        <h3 class="loginpanel__title display-block">Error</h3>
      </div>
      <div class="login-servererror-wrap">

      
        <p class="text1424">{{resendMessage}}</p>
      </div>
      <p class="login-tryagain" (click)="resenderror=false">Try again</p>

    </div>
  </div>
</div>

