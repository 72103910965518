<div class="page-wrapper">
    <div class="pagenavigator">
        <monthnavigator [(fromDate)]="actionProvider.fromDate" [(toDate)]="actionProvider.toDate"></monthnavigator>
        <p class="ok-button button-green" (click)="actionProvider.Create()">Add</p>
    </div>
    <dashboard-table *ngFor="let d of actionProvider.data" [data]="d" [context]="actionProvider">
    </dashboard-table>
</div>
<commentsystem-wizard [(visible)]="actionProvider.visible" [edit]="actionProvider.edit"  [context]="actionProvider.wizardContext"></commentsystem-wizard>
<sitesettings-dialog [(visible)]="actionProvider.siteSettingsVisible" [context]="actionProvider"></sitesettings-dialog>
<resumesite-dialog [(visible)]="actionProvider.resumeSiteVisible" [isResumeAll]="actionProvider.isResumeAll" [context]="actionProvider">
</resumesite-dialog>
