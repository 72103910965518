import { AbstractControl, ValidatorFn, ValidationErrors, AsyncValidatorFn, FormGroup } from "@angular/forms";
import { Helper } from "./helper";
const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

export class Customvalidators {
  public static validateIp(ipaddress:any) {
    if (/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(ipaddress)) {
      return (true)
    }
    return (false)
  }
  public static EmailValidator(): ValidatorFn {
//    var rg1 = /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/i;
  //  var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value == null || control.value.length == 0) {
        return { 'required': true };
      }
      if (Helper.NotEmpty(control.value) && EMAIL_REGEXP.test(control.value) == false) {
        return { 'Email': true };
      }
      return null;
    };
  }
 // ([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)
  public static IpValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(control.value))) {
        return { 'Ip': true };
      }
      return null;
    };
  }
  public static IpRangeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      var val = control.value;
      var range: any[] = val != null ? val.split("-") : [];
      if (range.length == 2 && !Number.isNaN(range[0]) && !Number.isNaN(range[1]) && range[0] > -1 && range[0] < 4095 && range[1] > -1 && range[1] < 4095 && range[0] < range[1]) {
        return null;
      }
      return { 'IpRange': true };

    };
  }
  public static NMLSIdValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      var val = control.value;
      var rg1 = /^[1-9][0-9]{10}/;
      if (val.length == 0)
        return { 'required': true };
      if (!rg1.test(val)) {
        return null;
      }
      return { 'NMLSId': true };

    };
  }
  //
  public static VmNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let value = control.value != null ? control.value + "" : "";
      if (value.includes("_") || value.includes(".")) {
        return { 'VmName': true };
      }
      return null;
    };
  }
  public static FileNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      var rg1 = /^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
      var rg2 = /^\./; // cannot start with dot (.)
      var rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names  
      var fname = control.value;
      if (!(rg1.test(fname) && !rg2.test(fname) && !rg3.test(fname))) {
        return { 'FileName': true };
      }
      return null;
    };
  }

  // private readonly Regex _productKeyPattern = new Regex(@"^([A-Z0-9]{5}-){4}[A-Z0-9]{5}$");
  public static ProductKeyValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      var rg1 = /^([A-Z0-9]{5}-){4}[A-Z0-9]{5}$/;
      var fname = control.value;
      if (fname == null || fname.length == 0)
        return null;
      if (!rg1.test(fname)) {
        return { 'ProductKeyName': true };
      }
      return null;
    };
  }

  public static ageRangeValidator(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {
        return { 'ageRange': true };
      }
      return null;
    };
  }
  public static TouchIvalidControl(fg: FormGroup) {
    Object.keys(fg.controls).forEach(function (key) {
      fg.controls[key].markAsUntouched();

    });
    fg.markAllAsTouched();
  }
}

export interface IUserCred {
  Password: any;
  ConfirmPassword: any;
};
export function PasswordValidator(data: IUserCred): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value == null || control.value.length == 0) {
      return { 'required': true };
    }
    if (data.Password == null || data.ConfirmPassword == null || data.Password.length == 0 || data.ConfirmPassword.length == 0)
      return null;
    data.Password = data.Password + '';
    data.ConfirmPassword = data.ConfirmPassword + '';
    if (data.Password != control.value) {
      return { 'notsame': true };
    }
    return null;
  };
}
