import { Component, Input, OnInit } from '@angular/core';
import { VisibleComponent, Wizard } from '../../../../components';
import {
    Helper,
    TopicSettingWizard,
} from '../../../../classes';
import { HttpService } from '../../../../services/http.service';
import { TopicSettingWizardData } from '../topicsettings.item';
import { DashboardActionProvider } from '../../DashboardActionProvider';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


enum ResumeValues {
    Current = 1,
    Unprocessed = 2,
    StartingFrom = 3,
}
@Component({
    selector: 'resumesite-dialog',
    templateUrl: './resumesite.dialog.html',
    styleUrls: ['./resumesite.scss']
})

export class ResumeSiteDialog extends VisibleComponent {
    constructor() {
        super();
    }
    selectedDate: string = '';
    resumeValues = ResumeValues;
    selectedValue: number = this.resumeValues.Current;

    @Input() isResumeAll: boolean = false;

    @Input()
    set context(val: DashboardActionProvider) {
        this._context = val;
    }
    get context(): DashboardActionProvider | undefined {
        return this._context;
    }
    _context: DashboardActionProvider | undefined;

    selectDate(value: string) {
        this.selectedDate = value;
    }

    onSelectedValueChange() {
        this.selectedDate = '';
    }

    wizard: Wizard = new Wizard(
        {
            wizardstyle: 'oncomments',
            likedialog: true,
            width: 388,
            steps: [
                {
                    title: 'Resume messages:',
                    Validate: () => {
                        return this.selectedValue === this.resumeValues.Current || this.selectedValue === this.resumeValues.Unprocessed ||
                            (this.selectedValue === this.resumeValues.StartingFrom && !!this.selectedDate);
                    },
                },
            ],
            OnOpen: () => {
                this.selectedDate = '';
                this.selectedValue = this.resumeValues.Current;
            },
            Finish: () => {
                let startTime;
                const currentISOTime = Helper.getISODateSimple(new Date());
                if (this.selectedValue === this.resumeValues.Current) {
                    startTime = currentISOTime;
                } else if (this.selectedValue === this.resumeValues.Unprocessed) {
                    startTime = this.context?.SelectedSite?.site?.comment_start_time || currentISOTime;
                } else if (this.selectedValue === this.resumeValues.StartingFrom) {
                    if (this.selectedDate) {
                        startTime = this.selectedDate;
                    }
                }
                if (startTime) {
                    if (this.isResumeAll) {
                        this.context?.ResumeAllSites(startTime);
                    } else {
                        this.context?.StartSite(startTime);
                    }
                }
            }
        });
}

