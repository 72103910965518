import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, Validators } from '@angular/forms';
import { Dialog, MultySelectOptions, PageMenuItem, VInputModel } from '../../components';
import { CommentSystemData, DataGridSettings, Helper, IUserCred, PasswordValidator, RouteNames, SitesStatistics } from '../../classes';
import { HttpService } from '../../services/http.service';
import { DashboardActionProvider } from './DashboardActionProvider';

enum SiteState {
    Paused = 0, //yellow
    Running = 1 //green + schedule
}
enum SchedulerState {
    Stopped = 0, //by system (error and other) red + desc
    Starting = 1, //green
    Running = 2, //green
    Waiting = 3, // waiting for ratelimit reset yellow + desc
    Failed = 4, // failed job 3 times red + desc
    QuotaExceeded = 5 // run out of quota yellow + desc
}
const SiteStatus = {
    Active: "active",
    Warning: "warning",
    Paused: "paused",
    Error: "error",
}
@Component({
    selector: 'dashboard-table',
    templateUrl: './dashboardtable.component.html'
})
export class DashboardTableComponent implements OnInit {
    constructor(public client: HttpService) { }
    public gridsettings = new DataGridSettings();

    @Input()
    set context(val: DashboardActionProvider) {
        this._context = val;
    }
    get context(): DashboardActionProvider | undefined {
        return this._context;
    }
    _context: DashboardActionProvider | undefined;
    @Input()
    set data(val: any) {
        this._data = val;
    }
    get data() {
        return this._data;
    }
    _data: CommentSystemData = {
        comment_system: {
            id: "1f6b30fc-bb05-4c5a-a92b-2dbfe1b655f5",
            name: "some2",
            type: 1
        },
        sites_and_statistics: []
    };
    public UserEmail = '';
    public dataSource$: any[] = [];
    public isActive = true;
    public siteStatus = SiteStatus;
    public menuitems: PageMenuItem[] = [
        {
            Visible: () => {
                return this.context?.SelectedSite?.site.state == SiteState.Running;
        },             
            name: 'Pause', click: () => {
                this.context?.PauseSite();
            }
        },
        {              
            Visible: () => {
                return this.context?.SelectedSite?.site.state == SiteState.Paused;
            },
            name: 'Resume', click: () => {
                this.context?.OpenResumeDialog(false);
            }
          },
        {            
              Visible: () => {
                  return true;
              },
              name: 'Edit', click: () => {
                  this.context?.EditSite();
              }
          },
        {          
            Visible: () => {
                return true;
            },
            name: 'Delete', click: () => {
              this.deletesiteDialogVisible = true;
              }
          }
    ];
 
    getSiteClass(site: any) {
        if (site.state === SiteState.Running) {
            if (site.scheduler_state === SchedulerState.Starting || site.scheduler_state === SchedulerState.Running) {
                return SiteStatus.Active;
            }
            if (site.scheduler_state === SchedulerState.Waiting || site.scheduler_state === SchedulerState.QuotaExceeded) {
                return SiteStatus.Warning;
            }
            if (site.scheduler_state === SchedulerState.Stopped || site.scheduler_state === SchedulerState.Failed) {
                return SiteStatus.Error;
            }
            return SiteStatus.Active;
        }
        if (site.state === SiteState.Paused) {
            return SiteStatus.Paused;
        }
        return SiteStatus.Warning;
    }
    public Edit() {
        this.context?.EditCommentSystem(this.data.comment_system.id, this.data.comment_system.type);
    }
    public Delete() {
         this.deleteDialogVisible = true;
    }
    StartAll() {
        this.context?.OpenResumeDialog(true, this.data?.comment_system?.id);
    }
    PauseAll() {
        this.context?.PauseAllSites(this.data?.comment_system?.id);
    }
    public rowActionClick(item: SitesStatistics) {
        if (this.context != null)
            this.context.SelectedSite = item;
    }
    Types: any;
    ngOnInit() {
        this.client.GetCurrentUser().then((u) => {
            this.UserEmail = u.email;
        });
        let that = this;
        this.client.GetTypes().then((types) => {
            that.Types = types;

        });
    }
    ChangeState() {
        this.isActive = !this.isActive;
    }
    IsStartAllActive() {
        if (!this?.data?.sites_and_statistics) return false;
        return this.data?.sites_and_statistics.some((s: any) => s.site.state === SiteState.Paused);
    }
    IsPauseAllActive() {
        if (!this?.data?.sites_and_statistics) return false;
        return this.data?.sites_and_statistics.some((s: any) => s.site.state === SiteState.Running);
    }
    get CommentSystemType() {
        if (this.Types != null && this.data != null && this.data.comment_system != null) {
            let res = this.Types.GetTypeNameById('comment_system_types', this.data.comment_system.type);
            return res;
        }

        return '';
    }
    get CommentSystemName() {
        return this?.data?.comment_system?.name || '';
  }
  deleteDialogVisible = false;

  public deleteDialog: Dialog = new Dialog(
    {   
      title: 'Delete comment system',
      width: 600,
      OnOpen: () => {


      },
      Validate: () => {
        return true;
      },
      Finish: () => {
        this.context?.DeleteCommentSystem(this.data.comment_system.id);
      }
    });

  deletesiteDialogVisible = false;
  get SelectedSiteName() {
    return this.context?.SelectedSite?.site.name || '';
  }
  public deletesiteDialog: Dialog = new Dialog(
    {
      title: 'Delete site',
      width: 600,
      OnOpen: () => {


      },
      Validate: () => {
        return true;
      },
      Finish: () => {
        this.context?.DeleteSite();
      }
    });
}


