import { Injectable } from "@angular/core";
import { BehaviorSubject, shareReplay, Subject } from "rxjs";
import { Guid } from "../classes";
export interface GoogleData {
  tokenId: string,
  name: string
}

@Injectable({providedIn: 'root'})
export class GoogleAuthService{    
  
    private googleDataSubject: BehaviorSubject<GoogleData> = new BehaviorSubject<GoogleData>({
      tokenId: Guid.EmptyStr,
      name: ''
    });

    public get googleData() {
      return this.googleDataSubject;
    }

    constructor(
    ) {
            
    }

    init(){
      const auth = (<any>window).google.accounts.id;
      auth.initialize({
        client_id: '1060337981418-n4t5op8gnf05jof1pf8h52opqt0bivs5.apps.googleusercontent.com',
        callback: this.handleCredentialResponse.bind(this)
      });

      auth.renderButton(
        document.getElementById("g_id_signin"),
        { theme: "outline", size: "large", width: "330" }        
      );
      console.log('init done');
    }

    handleCredentialResponse(response: any) {
        const data = this.parseJwt(response.credential);

        this.googleDataSubject.next({
            tokenId: response.credential,
            name: data.name
        })
    }

    parseJwt(token: string):any{
        const base64Url = token.split('.')[1];
        const base64 = decodeURIComponent(atob(base64Url)
            .split('')
            .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''));
        return JSON.parse(base64);
    }

    public Logout() {
      this.googleDataSubject.next({
        tokenId: Guid.EmptyStr,
        name: ''
      })
    }
}
