import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { Observable } from 'rxjs';
import { Helper, RouteNames } from '../../../classes';
import { Validators } from '@angular/forms';
import { VInputModel } from '../../../components';
import { HttpService } from '../../../services/http.service';
import { Customvalidators, } from '../../../classes';

class ContactUsModel {
  public Name: string='';
  public Surname: string = ''; 
  public Email: string = '';
  public Phone: string = '';
  public Message: string = '';
}

@Component({
  templateUrl: './contactus.component.html'
})
export class ContactUsComponent implements OnInit{ 
  static Path: "ContactUs"
  public Data: ContactUsModel = new ContactUsModel();
  public IsAgree = false;
  public PhoneMask = { Mask: '(000) 000 0000' };
  public EmailMask = Helper.EmailMask;
  public EmailPlaceHolder = 'yourmail@gmail.com';

  ngOnInit() {
   
  }
  public PhoneModel = new VInputModel({
    Label: '',
    errors: [{ key: 'required', value: 'Phone is required' }],
    Validators: [Validators.required],
  });  

  public NameModel = new VInputModel({
    Label: 'First name',
    errors: [{ key: 'required', value: 'First name is required' }],
    Validators: [Validators.required],
  });
  public SurnameModel = new VInputModel({
    Label: 'Last name',
    errors: [{ key: 'required', value: 'Last name is required' }],
    Validators: [Validators.required],
  });
  public EmailModel = new VInputModel({
    Label: 'yourmail@gmail.com',
    errors: [
      { key: 'required', value: 'Login is required' },
      { key: 'Email', value: 'Please enter a valid Email' }
    ],
    Validators: [Customvalidators.EmailValidator()],
  });
  public MessageModel = new VInputModel({
    Label: 'Your message',
    errors: [{ key: 'required', value: 'Your message is required' }],
    Validators: [Validators.required],
  });
  
  public get CanBeDone() { 
    let Phone = this.PhoneModel.Validate();
    let Name = this.NameModel.Validate();
    let Surname = this.SurnameModel.Validate();
    let Email = this.EmailModel.Validate();
    return Phone &&  Name && Surname && Email;
  }
  constructor(public client: HttpService, private router: Router) {
 
  }

  public ContactUs() {
    console.log(this.Data);


  }
} 
