import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouteNames, Helper, IAutrizationJobParameters } from '../../../classes';
import { HttpService } from '../../../services/http.service';

@Component({
  templateUrl: './autorizationdisqus.component.html'
})

export class AutorizationDisqusComponent implements OnInit{
  public resenderror = false;
  public resendMessage: any = "";
  public code: any = '';
  id: string = '';
  ngOnInit() {
    this.id = Helper.GetFromStorage("AutorizeJobId");
    let that = this;
    this.route.queryParams    
      .subscribe(params => {
        that.code = params['code'];
        that.SendAutorizationRequest();
      }
    );
  }
  
  constructor(public client: HttpService, private route: ActivatedRoute) {   
  }
 
  public SendAutorizationRequest() {
    let params: IAutrizationJobParameters = {
      id: this.id,
      type: 1,
      redirecturi: `${RouteNames.AutorizationDisqusUri}`,
      code: this.code
    };
    this.client.post<any>('api/authjob', JSON.stringify(params)).subscribe(id => {
      window.close();
    });
  }
} 
