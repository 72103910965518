<div class="tabs-menu" [ngClass]="{'tabs-menu-transparent': backgroundTransparent}">
  <div *ngIf="!withoutLink">
    <a *ngFor="let tab of tabs" [ngClass]="{'active': tab.active, 'disabled-with-opacity': tab.disable && tab.disable()}" [routerLink]="tab.link + (id ? id : '')">
      {{tab.name}}
    </a>
  </div>
  <div *ngIf="withoutLink">
    <span *ngFor="let tab of tabs; let i = index" [ngClass]="{'active': tab.active, 'disabled-with-opacity': tab.disable && tab.disable()}" (click)="selectTab(i)">
      {{tab.name}}
    </span>
  </div>
</div>
<ng-content></ng-content>
