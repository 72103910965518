import { EventEmitter} from '@angular/core';
import { AsyncValidatorFn,  FormControl,  FormGroupDirective, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
export function createEl(tag: any, classname: any) {
  var res = document.createElement(tag);
  res.className = classname;
  return res;
}
export interface IModalWindowSettings {
  visibleChange?  : EventEmitter<boolean>;
  onShow? : () => void;
  onClose? : () => void;
  clickBgClose? : () => boolean;
  removeScroll? : boolean;
}
export class MultySelectOptions {
  public Label: string = '';
  public SearchTitle: string = '';
  public WithoutCheckboxTitle: string = '';
  public ListCheckboxTitle: string = '';
  public DisplayExp: string = 'Name';
}
export class searchBoxSettings {
  public keyExp?: string;
  public displayExp?: string;
  public valueAll?: boolean;
  public blurtimeout?: number;
  public placeholder?: string;
  public listcount?: number;
  public search?: any;
  public value?: any;
  public onFocus?: () => void;
  public onBlur?: () => void;
  public lowExp?: string;
  public elExp?: string;
};

export class PageMenuItem {
  name: any='';
  click = () => { };
  btnclass? : any;
  class? : any;
  Enable ?= () => { return true; };
  Visible? = () => { return true; };
  public static NewItem(name: any) {
    let res = new PageMenuItem();
    res.name = name;
    return res;
  }
}

export class NeedValidateMatcher implements ErrorStateMatcher {
  constructor(private ctx: VInputModel) { }
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    if (this.ctx.needvalidate()) {
      const isSubmitted = false;//form && form.submitted;control.dirty ||
      let res = !!(control && control.invalid && (control.touched || isSubmitted));
      return res;
    }
    else {
      return false;
    }
  }
}
export interface VInputiIterface {
  errors? : { key: any, value: any }[];
  Label ?: any;
  Validators? : ValidatorFn[];
  AsyncValidators ?: AsyncValidatorFn[];
  needvalidate? : () => boolean;
}

export interface IMaskPatern {
  Mask: any;
  Patterns?: { [character: string]: { pattern: RegExp; optional?: boolean | undefined; symbol?: string | undefined; }; }
  // <input type = "text"[patterns]="customPatterns" mask = "(000-000)" />
  //public customPatterns = { '0': { pattern: new RegExp('\[a-zA-Z\]') } };
}

export class VInputModel {
  errors: { key: any, value:any }[] = [];
  Label: any = '';
  DefautltLabel: boolean = false;
  Validators : ValidatorFn[] = [];
  AsyncValidators : AsyncValidatorFn[] = [];
  FormControl: FormControl;
  public needvalidate: () => boolean;
  inputtype: any = 'text';
  get novalidate() { return this.errors.length == 0 };
  Matcher: NeedValidateMatcher;
  isdefault = false;
  constructor(item: VInputiIterface) {
    let k = new RegExp('\[a-zA-Z\]');
    let that: { [id: string]:any } = this;
    let data: any = item;
    this.FormControl = new FormControl('', []);
    Object.keys(item).forEach((key: any)=> {
      if (data[key] != null)
        that[key] = data[key];
    });
    if (item.Label == '')
      this.Label = undefined;
   
    (this.Validators)!.forEach(v => { this.FormControl.setValidators(v); });
    (this.AsyncValidators)!.forEach(av => { this.FormControl.setAsyncValidators(av); });
    if (item.needvalidate == undefined) {
      this.needvalidate = () => this.errors.length > 0;
    }
    else {
      this.needvalidate = () => item.needvalidate!();
    }
    this.Matcher = new NeedValidateMatcher(this);
  }

  public Validate() {
    if (this.needvalidate()) {
      this.FormControl.markAsTouched();
      return this.FormControl.valid;
    }
    else
      return true;
  }
  public hasError(key: any) {
    return this.FormControl.hasError(key);
  }
  public Untouched() {
    this.FormControl.markAsUntouched();
  }
  public static Defualt() {
    let res = new VInputModel({
      Label: 'Name'
    });
    res.isdefault = true;
    return res;
  }
  public static RequiredGModel(Label: any, error = 'Name is required') {
    let res = new VInputModel({
      Label: Label,
      errors: [{ key: 'required', value: error }],
      Validators: [Validators.required]
    });
    return res;
  }
}
