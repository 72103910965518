import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { MaterialAllModule } from './material.module';
import { BalanceAndQuotaDto, Helper, PaymentInfoDto, RouteNames } from './classes';
import { HttpService } from './services/http.service';
interface NavContext {
  Title: string;
  FirstLvlLink?: string;
  SecondLvlLink?: string;
}
interface MenuItem {
  Title: string;
  Link: string;
}
interface MenuItemGroup{
  Title: string;
  Link: string;
  Icon: string;
  IsOpen: boolean;
  Children: MenuItem[];
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit { 
  public Context: NavContext = { Title: 'Pipeline' }
  title = 'MortgageAccount';
  public UserName = '';
  public UserEmail = '';
  public authorized: boolean = false;
  public IsLoginLayout: boolean = false;
  public IsScafoldMenu: boolean = false;
  constructor(public client: HttpService, private router: Router) {
    router.events.subscribe((routerEvent) => {     
      if (routerEvent instanceof NavigationStart) {
        if (routerEvent.url.includes(RouteNames.Dashboard)) {
          HttpService.authorizedObserver.subscribe(Authorized => {
            if (!HttpService.Authorized) {
              this.client.navigate([RouteNames.Login]);
            }
          });
          this.IsLoginLayout = false;
        }       
        this.getContext(routerEvent.url);       
      }
    });
  }
  
  getContext(url: any) {
    let uri = Helper.transformUrl(url);
    let IsLoginLayout = true;
    switch (uri) {
      case `/`:
      case `/${RouteNames.Login}`:
      case `/${RouteNames.Registration}`:
      case `/${RouteNames.FogotPassword}`:
      case `/${RouteNames.ResetPassword}/id`:
      case `/${RouteNames.RegistrationCompleted}/id`:
      case `/${RouteNames.ContactUs}`:
        break;
      case `/${RouteNames.Dashboard}`:
        IsLoginLayout = false;
        this.tabs[0].active = true;
        this.tabs[1].active = false;
        break;
      case `/${RouteNames.Billing}`:
        IsLoginLayout = false;
        this.tabs[0].active = false;
        this.tabs[1].active = true;
        break;
      default:
        //  this.setContext(new NavigationContext());
        break;
    }
   
    this.IsLoginLayout = IsLoginLayout;
  }
  GetMenuGroupLink(menugroup: MenuItemGroup) {
    return menugroup.Link[0] == '@' ? menugroup.Children[0].Link : menugroup.Link;
  }
  
  public UserSettingsVisible = false;
  public CloseUserSettings() {
    this.UserSettingsVisible = false;
  }
  public ShowUserSettings() {
    this.UserSettingsVisible = true;
  }
  public tabs: { name: string, link: string, active: boolean }[] = [
    { name: 'dashboard', link:'/dashboard', active: false },
    { name: 'billing', link: '/billing', active: false },
  ];
  ngOnInit() {
    this.client.GetCurrentUser().then((u) => {
      this.UserName = u.name;
      this.UserEmail = u.email;
    });
  }
}
