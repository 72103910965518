<main class="main main-bg" *ngIf="IsLoginLayout">
  <div class="page-wrapper loginform-wrapper">
    <router-outlet></router-outlet>
    <!--<div class="loginpanel__copyright"><p>©2024 ai1 technologies. All rights reserved.</p></div>-->
  </div>
</main>
<main class="main main-bg" *ngIf="!IsLoginLayout">
  <div class="root" id="root">
    <div class="rootheader">
      <div class="rootheader__body">
        <div class="rootheader__logo"></div>
        <div class="rootheader__tabs">
          <p class="rootheader__tab "
             *ngFor="let tab of tabs"
             [routerLink]="tab.link"
             [ngClass]="tab.active?'rootheader__tab-active':''">{{tab.name}}</p>
        </div>
        <div class="rootheader__right">
          <debt-info />
          <notification />
          <user-info />
        </div>
      </div>
    </div>
    <div class="root-body gray-scrollbar">
      <div class="root-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</main>
