import { Component, Input, OnInit } from '@angular/core';
import { VInputModel, VisibleComponent, Wizard } from '../../../components';
import { CSWizardSettings, CommentSystemForCreationDto, RouteNames, Customvalidators, Guid, Helper, SiteForCreationDto, States, TopicAction, TopicSettingWizard, Settings, AccountEnum, TelegramDescrition } from '../../../classes';
import { HttpService } from '../../../services/http.service';
import { Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { TopicSettingWizardData } from './topicsettings.item';
import { DashboardActionProvider, DashboardWizardContext } from '../DashboardActionProvider';

enum StartValues {
    Current = 1,
    StartingFrom = 2,
}

interface TelegramInfoInterface {
    "id": string;
    "userName": string;
    "firstName": string;
    "token": string;
}

@Component({
    selector: 'commentsystem-wizard',
    templateUrl: './commentsystem.wizard.html'
})

export class CommentSystemWizard extends VisibleComponent implements OnInit {
    constructor(public client: HttpService) {
        super();
    }
    startValues = StartValues;
    selectedStartValue: number = this.startValues.Current;
    selectedDate: string = '';
    telegramInfo: TelegramInfoInterface = {
        "id": "",
        "userName": "",
        "firstName": "",
        "token": "",
  };
  public TelegramDescritionList: string[] = TelegramDescrition.list;
    @Input()
    set context(val: DashboardWizardContext) {
        this._context = val;
    }
  get context(): DashboardWizardContext | undefined {
        return this._context;
    }
  _context: DashboardWizardContext | undefined;
  telegraminfolink = '/assets/templates/telaegraminstruction.html';
  @Input()
  set edit(val: boolean) {
    if (this._edit != val) {
      this._edit = val;    
    }
  }
  get edit(): boolean {
    return this._edit;
  }
  _edit: boolean = false;
    public accountTypes = AccountEnum;
    public initialApiKey = '';
    public initialAccessToken = '';
    public id: string = '';
    public data: any[] = [];
    public UserName = '';
    public UserEmail = '';
    public restoreSettings = false;
  public telegramRedirectUri = RouteNames.AutorizationTelegramUri;
  public websites: { id: string, checked: boolean, disabled?: boolean, name: string, name_id: string }[] = [
    { id: Guid.NewGuidStr(), checked: false, name: 'n1', name_id: 'in1' },
    { id: Guid.NewGuidStr(), checked: false, name: 'n2', name_id: 'in1' },
    ]
    public TypeModel = new VInputModel({
        Label: 'Type',
        errors: [{ key: 'required', value: 'Type is required' }],
        Validators: [Validators.required],
    });
    public Types: { name: string, value: number }[] = [];
    public TopicTypes: { name: string, value: number }[] = [];
    public NameModel = new VInputModel({
        Label: 'Name',
        errors: [{ key: 'required', value: 'Name is required' }],
        Validators: [Validators.required],
    });
    public EmailModel = new VInputModel({
        Label: 'Login',
        errors: [
            { key: 'required', value: 'Login is required' },
            { key: 'Email', value: 'Please enter a valid E-mail' }
        ],
        //  Validators: [Validators.required],
        Validators: [Customvalidators.EmailValidator()],
    });
    public PasswordModel = new VInputModel({
        Label: 'Password',
        errors: [{ key: 'required', value: 'Password is required' }],
        Validators: [Validators.required],
    });

    public ApiKeyModel = new VInputModel({
        Label: 'Api Key',
        errors: [{ key: 'required', value: 'Client Id is required' }],
        Validators: [Validators.required],
    });

    public AccessTokenModel = new VInputModel({
        Label: 'AccessToken',
        errors: [{ key: 'required', value: 'Client Secret is required' }],
        Validators: [Validators.required],
    });

    ngOnInit() {
        this.client.GetCurrentUser().then((u) => {
            this.UserName = u.name;
            this.UserEmail = u.email;
        });
        let that = this;
        this.client.GetTypes().then((types) => {
            let Types: { name: string, value: number }[] = types.GetList('comment_system_types');
            that.Types = Types;
            that.TopicTypes = types.GetList('topic_types');
        });
    }

    TopicSettingsList: TopicSettingWizardData[] = [];
    private GetTopicSettingWizardList() {
        let res: TopicSettingWizardData[] = [];
        this.TopicTypes.forEach((tt) => {
            let tsw: TopicSettingWizardData = new TopicSettingWizardData();
            tsw.name = tt.name;
            tsw.type = tt.value;
            tsw.warning = 3;
            tsw.block = 7;
            res[res.length] = tsw;
        });
        return res;
    }

    commentSystem: CommentSystemForCreationDto = new CommentSystemForCreationDto();
    public GetSiteName(site: { name: string, name_id: string }) {
        return Helper.NotEmpty(site.name) ? site.name : site.name_id;
    }
  mapwebasites(sites: any[]) {
    let websites: { id: string, checked: boolean, disabled?: boolean, name: string, name_id: string }[] = [];
        sites.forEach((site) => {
          websites[websites.length] = {
                id: site.id,
                name: Helper.NotEmpty(site.name) ? site.name : site.name_id,
                name_id: site.name_id,
                checked: site.isActive,
                disabled: this.edit && this.model.comment_system.type === this.accountTypes.Telegram,
            }
        });
        return websites;
    }
    mapTopicSettingWizard(list: TopicSettingWizard[]) {
        let res: TopicSettingWizardData[] = [];
        let dic: { [id: string]: TopicSettingWizard[] } = {};
        let types: { [id: string]: { name: string, value: number } } = {};
        this.TopicTypes.forEach((tt) => {
            let id = tt.value + '';
            types[id] = tt;
        });
        list.forEach((item) => {
            let id = item.type + '';
            if (dic[id] != null) {
                dic[id][dic[id].length] = item;
            }
            else {
                dic[id] = [item];
            }
        });
        Object.keys(dic).forEach((key) => {
            let data = new TopicSettingWizardData();
            let tslist = dic[key];
            data.name = types[key].name;
            data.type = types[key].value;

            tslist.forEach(ts => {
                data.checked = ts.isActive;
                if (ts.action == TopicAction.Block) {
                    data.block = ts.value;
                }
                if (ts.action == TopicAction.Tag) {
                    data.warning = ts.value;
                }
            });
            res[res.length] = data;
        });
        return res;
    }
    AutorizeJobId: any;
    model: CSWizardSettings = new CSWizardSettings();
    wizard: Wizard = new Wizard(
      {
        wizardstyle: 'oncomments',
       // allstepenabled: () => { return true; },
            steps: [
                {
                menutitle: '1',
                title: 'Adding an account',
                     Visible: () => { return !this.edit },
                    Validate: this.ValidateFirstStep.bind(this),
                    AsyncValidateOnDone: this.AsyncValidateOnDone.bind(this),
                },
                {
                  menutitle: '1',
                  title: 'Editing an account',
                  Validate: this.ValidateFirstStep.bind(this),
                  AsyncValidateOnDone: this.AsyncValidateOnDone.bind(this),
                  Visible: () => {
                    return this.edit
                  },
                },
                {
                    title: 'Websites',
                    menutitle: '2',
                    subtitle: 'Select websites for moderation.',
                   
                    Validate: () => { return this.websites.filter((s) => s.checked).length >= 0; },
                    Visible: () => this.model.comment_system.type !== this.accountTypes.Telegram,
                },
                {
                    title: 'Telegram',
                    menutitle: '2',
                    Visible: () => this.model.comment_system.type === this.accountTypes.Telegram,
                },
                {
                    title: 'Default moderation settings',
                    subtitle: 'Assign default thresholds values. Settings can be redefined separately for each website.',
                    menutitle: '3'
                },
                {
                    title: 'Process messages',
                    menutitle: '4',
                    Validate: () => {
                        return this.selectedStartValue === this.startValues.Current ||
                            (this.selectedStartValue === this.startValues.StartingFrom && !!this.model.comment_start_time);
                    },
                    Visible: () => !this.edit && this.model.comment_system.type !== this.accountTypes.Telegram,
                },
            ],
            OnOpen: () => {             
                if (!this.edit) {
                    this.AutorizeJobId = Guid.NewGuidStr();
                    Helper.SaveToStorage("AutorizeJobId", this.AutorizeJobId);
                }
                this.model = new CSWizardSettings();
                this.NameModel.Untouched();
                if (this.edit) {
                    this.id = this.context?.selectedCS || '';
                    this.model.comment_system.type = this.context?.selectedType || 0;
                    this.wizard.isloading = true;
                    this.client.get<any>(`api/Wizard/${this.id}`).subscribe(
                        {
                            next: (res) => {
                                console.log('edit data = ', res);
                            this.model.comment_system.name = res.comment_system.name;
                            this.model.comment_system.id = this.id;
                            if (this.model.comment_system.type === this.accountTypes.Disqus) {
                              const auth = JSON.parse(res.comment_system.auth);
                              this.model.comment_system.auth = res.comment_system.auth;
                              this.model.comment_system.api_key = this.initialApiKey = auth.api_key;
                              this.model.comment_system.access_token = this.initialAccessToken = auth.access_token;
                            } else  if (this.model.comment_system.type === this.accountTypes.Telegram){
                              this.model.comment_system.auth = res.comment_system.auth;
                            }
                                this.websites = this.mapwebasites(res.sites);
                                this.TopicSettingsList = this.mapTopicSettingWizard(res.topic_settings);
                                this.wizard.isloading = false;
                            },
                            error: (error) => {
                                this.wizard.isloading = false;
                            },
                        });
                }
                else {
                    this.TopicSettingsList = this.GetTopicSettingWizardList();
                    this.TypeModel.Untouched();
                    this.EmailModel.Untouched();
                    this.PasswordModel.Untouched();
                    this.AccessTokenModel.Untouched();
                    this.ApiKeyModel.Untouched();
                }

            },
            Finish: () => {
                this.websites.filter((s) => s.checked).forEach((site) => {
                    let wsite = new SiteForCreationDto();
                    wsite.comment_system_id = this.model.comment_system.id;
                    wsite.name = site.name;
                    wsite.name_id = site.name_id;
                    wsite.id = site.id;
                    this.model.sites[this.model.sites.length] = wsite;
                });
                let tslist: TopicSettingWizard[] = [];
                this.TopicSettingsList.forEach((ts) => {
                    let tsw = new TopicSettingWizard();
                    tsw.action = TopicAction.Tag;
                    tsw.isActive = ts.checked;
                    tsw.type = ts.type;
                    tsw.value = ts.warning;
                    tslist[tslist.length] = tsw;
                    let tsb = new TopicSettingWizard();
                    tsb.action = TopicAction.Block;
                    tsb.isActive = ts.checked;
                    tsb.type = ts.type;
                    tsb.value = ts.block;
                    tslist[tslist.length] = tsb;
                });
                const isTelegram = this.model.comment_system.type === this.accountTypes.Telegram;

                this.model.topic_settings = tslist;
                if (this.selectedStartValue === this.startValues.Current) {
                    this.model.comment_start_time = Helper.getISODateSimple(new Date());
                }
              let data: any = {};
              if (this.edit) {
                data = {
                  comment_system: {
                    name: this.model.comment_system.name,
                    auth: this.model.comment_system.auth
                  },
                  sites: [ ],
                  topic_settings: this.model.topic_settings,
                  reset_all_topic_settings: this.restoreSettings
                }
              } else {
                 data = {
                  comment_system: {
                    name: this.model.comment_system.name,
                    auth: this.model.comment_system.auth,
                    type: this.model.comment_system.type,
                    id: this.model.comment_system.id,
                  },
                  topic_settings: this.model.topic_settings,
                  reset_all_topic_settings: this.restoreSettings
                }
              }
              
              if(!isTelegram) {
                    data.comment_start_time = this.model.comment_start_time;
                    data.sites = this.model.sites;
                }
                if (this.edit) {
                    this.client.put(`api/Wizard/${this.id}`, data).subscribe((res) => {
                        this.context?.Load();
                    });
                } else {
                    this.client.post('api/Wizard', data).subscribe((res) => {
                        this.context?.Load();
                    });
                }
            }
        });

    Autorize() {
        if (this.model.comment_system.type == 1) {
            this.AutorizeDisqus()
        }
        if (this.model.comment_system.type == 2) {
            this.AutorizeTelegram()
        }
    }

    public ValidateFirstStep() {
      const isNameValid = this.NameModel.Validate();
      const isTypeValid = this.TypeModel.Validate() || this.edit;
        if (!isNameValid || !isTypeValid) return false;
        if (this.model.comment_system.type === AccountEnum.Disqus) {
            return this.ApiKeyModel.Validate() && this.AccessTokenModel.Validate();
        }
        if (this.model.comment_system.type === AccountEnum.Telegram) {
            return this.model.comment_system.type &&
                Helper.NotEmpty(this.model.comment_system.auth);
        }
        return false;
    }

    AsyncValidateOnDone(){
      return new Promise<boolean>((resolve, reject) => {
        const isDisqus = this.model.comment_system.type === this.accountTypes.Disqus;
        const isTelegram = this.model.comment_system.type === this.accountTypes.Telegram;
        if (isDisqus) {
          this.model.comment_system.auth = JSON.stringify(
            {
              api_key: this.model.comment_system.api_key,
              access_token: this.model.comment_system.access_token,
            }
          );
        }
        let authdata =  {
          id: this.model.comment_system.id,
          name: this.model.comment_system.name,
          type: this.model.comment_system.type,
          auth: this.model.comment_system.auth
        }
        this.client.post<boolean>('api/wizard/checkauthdata', authdata).subscribe(
          (res: boolean) => {
            if (isDisqus) {
              if (!this.edit || (this.edit && this.IsDisqusDataChanged())) {
                this.client.post<any[]>('api/wizard/GetCommentSystemSites', {
                  id: this.model.comment_system.id,
                  name: this.model.comment_system.name,
                  type: this.model.comment_system.type,
                  auth: this.model.comment_system.auth
                }).subscribe({
                  next: (res) => {
                    this.websites = this.mapwebasites(res);
                    if (res) {
                      resolve(true);
                    }
                  },
                  error: (error) => {
                    console.log('error = ', error);
                    reject();
                  },
                  complete: () => {
                    resolve(true);
                  }

                });
              } else {
                resolve(true);
              }
            }
            if (isTelegram) {
              this.client.get<TelegramInfoInterface>('api/wizard/TelegramBotInfo').subscribe({
                next: (res) => {
                  this.telegramInfo = res;
                },
                complete: () => {
                  resolve(true);
                }
              });
            }
          },
          error => {
            reject();
          });
      })
  }

    public IsDisqusDataChanged() {
        return this.initialAccessToken != this.model.comment_system.access_token ||
            this.initialApiKey != this.model.comment_system.api_key;
    }

    public AutorizeTelegram() {
        var redirecudlr = RouteNames.AutorizationTelegramUri;
        let basepath = 'https://oauth.telegram.org/auth';
        let YOUR_BOT_ID = '7371703452';
        console.log(encodeURIComponent(location.origin || location.protocol + '//' + location.hostname));
        var url: any = `${basepath}?bot_id=${YOUR_BOT_ID}&origin=${encodeURIComponent(RouteNames.BaseUri)}&request_access=write&return_to=${encodeURIComponent(RouteNames.AutorizationTelegramUri)}`;
        window.open(url, '_blank');
    }

    public logout() {
        location.href = 'https://oauth.telegram.org/auth/logout?bot_id=7371703452&origin=https%3A%2F%2Fmy.oncomments.com&embed=1&request_access=write&hash=2dd8a8ac1ce11650db7b264b61ca259eda48a1f392fa7b804b31afcd5fd257d1';
        return false;
    }

    public AutorizeDisqus() {
        var redirecudlr = RouteNames.AutorizationDisqusUri;
        var url: any = 'https://disqus.com/api/oauth/2.0/authorize/?' +
            `client_id=${Settings.DisqusPublicKey}&scope=admin&response_type=code&redirect_uri=${redirecudlr}`;
        window.open(url, '_blank');
        this.LoadDisqus();
    }

    public AutorizeDisqusApplicationPerUser() {

        var redirecudlr = RouteNames.AutorizationDisqusUri;
        var url: any = 'https://disqus.com/api/oauth/2.0/authorize/?' +
            `scope=admin&response_type=api_key&redirect_uri=${redirecudlr}`;
        window.open(url, '_blank');
        this.LoadDisqus();
    }

    private LoadDisqus() {
        this.wizard.isloading = true;
        let obs = new Observable<any>(sub => {
            let getp = () => {
                let unsubscribe = () => {
                    s.unsubscribe();
                    sb.unsubscribe();
                };
                let s = this.client.get<any>(`api/authjob/${this.AutorizeJobId}/results`, undefined).subscribe(data => {
                    sub.next(data);
                },
                    error => {
                        if (!this.wizard.isloading) {
                            unsubscribe();
                            return;
                        }
                        if (error.status = 404) {
                            setTimeout(() => {
                                s.unsubscribe();
                                getp();
                            }, 5000);
                        }
                        else {
                            sub.next(undefined);
                            s.unsubscribe();
                        }
                    }
                );
            }
            getp();
        });
        let sb = obs.subscribe(data => {
            this.wizard.isloading = false;
            this.model.comment_system.auth = Helper.stringify(data);
            sb.unsubscribe();
        });

    }

    selectDate(value: string) {
        this.model.comment_start_time = value;
    }
}
