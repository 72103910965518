import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { HttpService } from '../../services/http.service';
import { BalanceAndQuotaDto, InvoiceDto, PaymentInfoDto, PriceDto, UserModel } from '../../classes';

declare var paddleWrapper: any;
@Component({
  templateUrl: './billing.component.html'
})
export class BillingComponent implements OnInit{
  constructor(public client: HttpService, private route: ActivatedRoute) {  }
  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  public data: InvoiceDto[] = [];
  balance: BalanceAndQuotaDto = new BalanceAndQuotaDto();
  paymentInfoDto: PaymentInfoDto = new PaymentInfoDto();
  Quota: number = 0;
  user: any;
  public prices: PriceDto[] = [];

  ngOnInit(): void {
    let that = this;
    this.client.get<InvoiceDto[]>('api/payment/invoices').subscribe((res) => {
      console.log(res);
      that.data = res;
    });

    this.client.get<PriceDto[]>('api/payment/prices').subscribe((res) => {
      console.log(res);
      that.prices = res;
    });
    this.client.get<BalanceAndQuotaDto>('api/payment/balance').subscribe((res) => {
      console.log(res);
      that.balance = res;
    });
    this.client.get<PaymentInfoDto>('api/payment/payment_info').subscribe((res) => {
      console.log(res);
      that.paymentInfoDto = res;
    });
    this.client.GetCurrentUser().then((user) => {
      this.user = user;
    });
  }
  
  public GetDate(date: string) {
    let d = new Date(date);
    let m = this.months[ d.getMonth()];
    let res = `${m} ${d.getDate()} ${d.getFullYear() }`;
    if (d.getFullYear() < 2000)
      return '';
    return res;
  }

  updateSubscription() {
      this.client.GetPaymentTransactionId().subscribe((res) => {    
      let transactionId = res; 
      paddleWrapper.updateSubscription(transactionId, this.user.id);
    });   
  }

  startSubscription(priceId: string) {
    paddleWrapper.startSubscription(priceId, this.user.id);
  }
  
  getPayBtnType() {
    return this.prices.filter(p => p.is_active).length==1? 'billinfopanel__greenbtn':'';
  }

  getCardDate() {
    return this.paymentInfoDto.expiry_year_card != null && this.paymentInfoDto.expiry_month_card ?
      `${this.paymentInfoDto.expiry_year_card}/${this.paymentInfoDto.expiry_month_card}` : '__';
  }
  getCard4Number() {
    let res = this.paymentInfoDto.last_four_card != null ? this.paymentInfoDto.last_four_card : '__';
    return res;
  }
  getCardType() {
    return this.paymentInfoDto.type_card != null ? 'master-card' : '';
  }
  
  public GetStausClass(payment: InvoiceDto) {
    return 'status-green';
  }

  public GetStausText(payment: InvoiceDto) {

    return payment.status != null ? payment.status:'';
  }

} 
