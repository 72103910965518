<div class="loginform">
  <div>
    <div class="loginpanel" *ngIf="!resenderror">
      <div class="loginpanel__img"></div>
      <div *ngIf="!wasSending">
        <p class="loginpanel__title">New password</p>
        <p class="loginpanel__subtitle marginb32">Please create a new password.</p>
        <div class="input-label-block">
          <div class="input-label-wrapper">
            <p>Password<span>*</span></p>
          </div>
          <v-password [model]="PasswordModel" [(value)]="Data.Password"></v-password>
        </div>
        <div class="input-label-block marginb32">
          <div class="input-label-wrapper">
            <p>Confirm Password<span>*</span></p>
          </div>
          <v-password [model]="ConfirmPasswordModel" [(value)]="Data.ConfirmPassword"></v-password>
        </div>
        <p class=" ok-button  button-blue marginb32" (click)="resetPassord()">Change</p>
        <p class="singup-link-wrapper">Need Help? <a class="green-link singup-link" [routerLink]="'/contactus'">Contact us</a></p>
      </div>
      <div *ngIf="wasSending">
        <p class="loginpanel__title">Success!</p>
        <br />
        <p class="loginpanel__subtitle marginb32"> Your password has been changed.</p>

        <p class="singup-link-wrapper"> <a [routerLink]="'/login'" class="green-link singup-link">Sign in</a></p>
      </div>
     
    </div>

    <div class="login-servererror loginpanel" *ngIf="resenderror">
      <div style="height:56px;">
        <h3 class="loginpanel__title display-block">Error</h3>
      </div>
      <div class="login-servererror-wrap">
        <p class="text1424">{{resendMessage}}</p>
      </div>
      <p class="login-tryagain" (click)="resenderror=false">Try again</p>

    </div>
  </div>
</div>

