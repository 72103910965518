<icon-dropdown [icon]="isNotification?'notification-btn notification-btnactive':'notification-btn'"
    dropdownclass="notificationlist" [(open)]="open">
    <div class="notifications">
        <div class="notifications__toppanel">
            <p class="icon-close" (click)="close()"></p>
        </div>
        <div class="notifications__titlecontainer">
            <p class="notifications__title">Notifications</p>
            <span class="notifications__discard" (click)="discardAll()" *ngIf="!selectedNotification && isNotification">
                discard all
            </span>
        </div>
        <div class="notifications__switchcontainer" *ngIf="!selectedNotification">
            <span (click)="setSelected(false)" [ngClass]="{'active': !isSelectedAll}">
                new
            </span>
            <span (click)="setSelected(true)" [ngClass]="{'active': isSelectedAll}">
                view all
            </span>
        </div>
        <div class="notifications__body gray-scrollbar">
            <div *ngIf="!selectedNotification">
                <div class="notification" [ngClass]="{'notification-active': isUnreadNotification(notification)}"
                    *ngFor="let notification of visibleNotifications">
                    <p class="notification__title" (click)="selectNotification(notification)">
                        <span class="notification-description">{{notification.description}}</span>
                        <span class="notification-time-diff">{{getTimeDifferance(notification)}}</span>
                    </p>
                </div>
                <div *ngIf="visibleNotifications.length === 0">
                    {{isSelectedAll? 'No notifications' : 'No new notifications'}}
                </div>
            </div>
            <div *ngIf="selectedNotification">
                <div class="notification__back" (click)="selectNotification(null)">
                    back
                </div>
                <div class="notification notification-single">
                    <p class="notification__title">
                        <span class="notification-description">{{selectedNotification.description}}</span>
                        <span class="notification-time-diff">{{getTimeDifferance(selectedNotification)}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</icon-dropdown>