import { Component,  OnInit, ElementRef, Input, EventEmitter, Output, OnDestroy } from '@angular/core';

import { BalanceAndQuotaDto } from '../../classes';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'debt-info',
  templateUrl: './DebtInfo.component.html'
})

export class DebtInfoComponent {
  public debt = '';

  constructor(public client: HttpService) {
    this.client.get<BalanceAndQuotaDto>('api/payment/balance').subscribe((res) => {
        this.debt = res.balance+'';
    });
  }
}


