import { Component, OnInit, Injectable, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { IModalWindowSettings } from '..';
import { VisibleComponent } from '../visiblecomponent';
import { Helper } from '../../classes';

export interface IWizardStep {
  title: string;
  menutitle?: string;
  subtitle?: string;
  icon?: string;
  Visible?: () => boolean;
  Validate?: () => boolean;
  ErrorMessage?: () => string;
  OnLoad?: () => void;
  OnOpen?: () => void;
  OnDone?: () => void;
  AsyncValidateOnDone?: () => Promise<boolean>;
  Loaded?: boolean;
  loadanywhere?: boolean;
  stepDone?: boolean;
}

export interface IWizard {
  title?: string;
  steps?: IWizardStep[];
  allstepenabled?: () => boolean,
  wizardstyle?: string;
  contentclass?: string;
  isloading?: boolean;
  validateOnNext?: boolean;
  OnInit?: () => void;
  OnOpen?: () => void;
  OnClose?: () => void;
  Finish?: () => void;
  GetTitle?: () => string;
  width?: number;
  likedialog?: boolean;
}

export class WizardStep {
  title: any = '';
  menutitle?: string;
  subtitle? : any;
  icon? : any;
  Visible = () => { return true };
  Validate = () => { return true };
  ErrorMessage? : () => any;
  Valid = true;
  OnLoad = () => { this.Loaded = true; };
  OnOpen = () => { };
  OnDone = () => { };
  AsyncValidateOnDone = () => Promise.resolve(true);
  Loaded = false;
  stepDone = false;
  touched = false;
  loadanywhere = false;
 
  constructor(w: IWizardStep) {
    let that: { [id: string]: any } = this;
    let settings: { [id: string]: any } = w;
    Object.keys(w).forEach(function (key) {
      if (settings[key] != null) {
        if (key == 'Validate') {
          that['Validate'] = () => { that['Valid'] = w.Validate!(); return that['Valid']; }
        }
        else
          that[key] = settings[key];
      }
    });
  }
}

export class Wizard {
  public title: any = '';
  public contentclass = '';
  public wizardstyle = 'clasic';
  public stepnum: number = 0;
  public ErrorMessage = '';
  public ErrorMessageVisible = false;
  public validateOnNext = true;
  public likedialog = false;
  width = 800;
  public GetTitle : () => any = () => { return this.title; };
  get stepmax(): number {
    let stepdone = -1;
    let stepvalid = -1;
    for (var i = 0; i < this.steps.length; i++) {
      let step = this.steps[i];
      if (stepdone < 0 && step.Visible() && !step.stepDone)
        stepdone = i;
    }
    if (stepdone < 0)
      stepdone = this.steps.length - 1;
    for (var i = 0; i <= stepdone; i++) {
      let step = this.steps[i];
      if (step.Visible() && stepvalid < 0 && !step.Valid)
        stepvalid = i;
    }
    if (stepvalid < 0)
      stepvalid = stepdone
    return stepvalid;
  }
  get laststep() {
    let stepvisible = -1;
    for (var i = 0; i < this.steps.length; i++) {
      let step = this.steps[i];
      if ( step.Visible())
        stepvisible = i;
    }
    return stepvisible;
  }
  public stepclass(stepnum: number) {
    let res = '';
    let step = this.steps[stepnum];
    if (step.icon != null && step.icon.length>0)
      res += step.icon + ' ';
    if (!step.Visible())
      res += 'hide ';
    let en = this.allstepenabled()
    if (!en) {
      if (!(this.stepmin <= stepnum && stepnum <= this.stepmax)) {
        res += 'disabled ';
      }
    }
    else {
      res += 'wizard-menuitem-done ';
    }
    if (step.stepDone) {
      res += 'wizard-menuitem-done ';
    }
    if (this.stepnum == stepnum)
      res += 'wizard-menuitem-active ';
   
    return res;
  }
  public stepmin: number = 0;
  public steps: WizardStep[] = [];
  public isloading: boolean = false;
  public Finished = false;
  public init = false;
  public LoadStep(stepnum: number) {
    if ((stepnum <= this.stepmax && stepnum >= this.stepmin && this.stepnum != stepnum) || this.allstepenabled()) {
      this.loadStep(stepnum);
    }
  }
  private loadStep(stepnum: number) {
    let step = this.steps[this.stepnum];
    step.OnDone();
    step = this.steps[stepnum];
    if (step.loadanywhere || !step.Loaded) {
      step.OnLoad();
      step.Loaded = true;
    }
    step.OnOpen();
    step.touched = false;
    this.stepnum = stepnum;
    Helper.ESresize();
  }
  public Next() {
    let step = this.steps[this.stepnum];
    step.touched = true;
    if (step.Validate()) {
      step.AsyncValidateOnDone().then((res) => {
        step.stepDone = true;
        let stepnum = this.findnext();
        this.loadStep(stepnum);
      })
    }
    else {
      if (step.ErrorMessage != null) {
        this.ErrorMessage = step.ErrorMessage();
        this.ErrorMessageVisible = true;
      }
    }
  };
  public Prior() {
    if (this.CanPrior()) {
      let stepnum = this.findprior();
      this.LoadStep(stepnum);
    }
  };
  public OnOpen = () => {};
  public OnInit = () => { };
  public OnClose = () => { this.init = false;};
  public Finish = () => { };
  public Validate() { };
  public allstepenabled = () => { return false; }
  public onopen() {
    this.Finished = false;
    this.stepnum =  this.stepmin = this.findmin();
    for (var i = 0; i < this.steps.length; i++) {
      this.steps[i].stepDone = false;
      this.steps[i].Loaded = false;
    }
  };
  findmin() {
    let f = false;
    let num = -1;
    for (var i = 0; i < this.steps.length; i++) {
      let s = this.steps[i];
      if (s.Visible() == true && !f) {
        num = i; f = true;
      }
    }
    return num;
  }
  public CanPrior() {
    let step = this.findprior();
    return (step >= this.stepmin && step < this.stepnum);
  };
 
  public CanNext() {
    let next = this.findnext();
    let step = this.steps[this.stepnum];
    let res = false;
    if (!this.isloading && step.ErrorMessage == null) {
      if (this.validateOnNext)
        res = (!step.touched || step.Validate()) && next > this.stepnum;
      else
        res = step.Validate() && next > this.stepnum;      
    }
    else {
      res = next > this.stepnum;
    }
    //  let res = !this.isloading && step.ErrorMessage == null    (this.validateOnNext   !step.touched || step.Validate() : step.Validate()) && next > this.stepnum : next > this.stepnum;
    return res;
  };
  public CanFinish() {
    let curstep = this.steps[this.stepnum];
    return (this.allstepenabled() && (!curstep.touched || curstep.Validate())) || (this.stepmax == this.laststep &&  curstep.Validate());
  };

  public findnext() {
    for (var i = this.stepnum + 1; i < this.steps.length; i++) {
      let s = this.steps[i];
      if (s.Visible == null || (s.Visible != null && s.Visible() == true))
        return i;
    }
    return this.stepnum;
  };
  public findprior() {
    for (var i = this.stepnum - 1; i >= this.stepmin; i--) {
      let s = this.steps[i];
      if (s.Visible == null || (s.Visible != null && s.Visible() == true))
        return i;
    }
    return this.stepnum;
  };
 
  constructor(w: IWizard) {
    let that: { [id: string]: any } = this;
    let settings: { [id: string]: any } = w;
    Object.keys(w).forEach(function (key) {
      if (settings[key] != null) {
        if (key == 'steps') {
          let steps = new Array<WizardStep>();
          for (var i = 0; i < w.steps!.length; i++) {
            steps[i] = new WizardStep(w.steps![i]);
          }
          that['steps'] = steps;
        }
        else
          that[key] = settings[key];
      }
    });
    this.OnOpen = () => {
      this.onopen();
      if (w.OnOpen != null) {
        w.OnOpen();
      }
      this.LoadStep(this.stepnum);
    };
   
    if (w.OnClose != null) {
      this.OnClose = () => {
        w.OnClose!();
        this.init = false;
      };
    }
    if (w.Finish != null) {
      this.Finish = () => {
        w.Finish!();
      };
    }

  }
}
@Component({
  selector: 'wizard',
  templateUrl: 'Wizard.component.html'
})

@Injectable()
export class WizardComponent extends VisibleComponent implements OnInit {
  @Output()
  public isloadingChange = new EventEmitter<boolean>();
  @Input()
  set isloading(val: boolean) {
    if (this.data.isloading != val) {
      this.data.isloading = val;
      this.isloadingChange.emit(val);
    }
  }
  get isloading(): boolean {
    return this.data.isloading;
  }
  _data: Wizard = new Wizard({});
  @Input()
  get data(): Wizard {
    return this._data;
  }
  set data(val: Wizard) {
    this._data = val;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
    this.visibleChange.subscribe(v => {
      if (v) {
        this.data.OnInit();
        this.data.init = true;
        this.data.OnOpen();
      } else {
        this.data.OnClose();
      }
    });
  }

  ngOnInit() {

  }

  public Next() {
    setTimeout(() => {
      this.data.Next();
    }, 150);
  }
  public Prior() {
    setTimeout(() => {
      this.data.Prior();
      this.changeDetectorRef.detectChanges();
    }, 150);
  }
  public Finish() {
    if (!this.data.Finished) {
      this.data.Finished = true;
      this.data.Finish();
      this.visible = false;
    }

  }

  public Open() {
    this.visible = true;
  }

}


