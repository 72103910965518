import { Component, OnInit, ElementRef, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import moment from 'moment';
import { Observable, BehaviorSubject } from 'rxjs';
import { Guid, Helper } from '../../classes';
import { HttpService } from '../../services/http.service';

class Notification {
    id: string = Guid.NewGuidStr();
    description: string = '';
    created_date: Date = new Date();
    state: number = 0;
}

enum NotificationState 
{
    //Draft = 0,
    Created = 0,
    //Sent = 2,
    Read = 1,
}
@Component({
    selector: 'notification',
    templateUrl: './Notification.component.html'
})

export class NotificationComponent implements OnInit, OnDestroy {
    public open = false;
    public get isNotification() {
        let result = this.notifications.filter((n) => n.state === NotificationState.Created).length > 0;
        return result;
    }
    public notifications: Notification[] = [
        // {
        //     id: Guid.NewGuidStr(),
        //     description: 'Your request is being processed',
        //     created_date: new Date(),
        //     state: NotificationState.Created
        // },
        // {
        //     id: Guid.NewGuidStr(),
        //     description: 'Your request is being processed Your request is being processed Your request is being processedYour request is being processed',
        //     created_date: new Date(),
        //     state: NotificationState.Created
        // },
        // {
        //     id: Guid.NewGuidStr(),
        //     description: 'Your request is being processed',
        //     created_date: new Date(),
        //     state: NotificationState.Created
        // },
        // {
        //     id: Guid.NewGuidStr(),
        //     description: 'Your request is being processed Your request is being processed',
        //     created_date: new Date(),
        //     state: NotificationState.Read
        // },
        // {
        //     id: Guid.NewGuidStr(),
        //     description: 'Your request is being processed',
        //     created_date: new Date(),
        //     state: NotificationState.Read
        // }
    ];
    isSelectedAll: boolean = false;
    selectedNotification: Notification | null;

    isReadNotification(n: Notification) {
        return n.state === NotificationState.Read;
    }

    isUnreadNotification(n: Notification) {
        return n.state === NotificationState.Created;
    }

    constructor(public client: HttpService) {
        this.selectedNotification = null;
    }

    ngOnInit(): void {
        this.client.get('api/notification').subscribe((res: any) => {
            console.log('get notifications = ', res);
            this.notifications = res;
        });
    }

    get visibleNotifications() {
        if (!this.notifications) return [];
        if (this.isSelectedAll) return this.notifications;
        return this.notifications.filter(n => n.state === NotificationState.Created);
    }

    selectNotification(notification: Notification | null) {
        setTimeout(() => {
            if(notification && this.isUnreadNotification(notification)) {
                this.setNotificationSelected([notification])
            }
            this.selectedNotification = notification;
        }, 0);
    }

    discardAll() {
        this.setNotificationSelected(this.notifications.filter(n => n.state === NotificationState.Created))
    }

    setNotificationSelected(notifications: Notification[]) {
        this.client.post('api/notification', {
            "ids": notifications.map(n => n.id)
        }).subscribe((res) => {
            console.log('set read', res);
            notifications.forEach(n => {
                n.state = NotificationState.Read;
            })
        });
    }

    setSelected(isSelectedAll: boolean) {
        this.isSelectedAll = isSelectedAll;
    }

    ngOnDestroy() {

    }
    close() {
        this.open = false;
    }
    
    getTimeDifferance(notification: Notification) {
        const a = moment();
        const b = moment(notification.created_date);
        const seconds = a.diff(b, 'seconds');
        const minutes = a.diff(b, 'minutes');
        const hours = a.diff(b, 'hours')
        const days = a.diff(b, 'days');
        const months = a.diff(b, 'months');
        const years = a.diff(b, 'years');

        if(years > 0){
            return years +' y ago';
        } else if(months > 0) {
            return months +' m ago';
        } else if(days > 0) {
            return days +' d ago';
        } else if(hours > 0) {
            return hours +' h ago';
        } else if(minutes > 0) {
            return minutes +' m ago';
        } else if(seconds > 0) {
            return seconds +' s ago';
        }
        return '';
    }
}


