<div class="loginform">
  <div>
    <div class="loginpanel"  [ngClass]="!loginError.isThrow?'':'hide'">
      <div class="loginpanel__img"></div>
      <p class="loginpanel__title marginb32">Log in</p>
      <v-input [model]="EmailModel"
               [(value)]="Data.email"
               [InputPlaceholder]="EmailPlaceHolder"
               [Mask]="EmailMask"
               [ShowMaskTyped]="false"> </v-input>
      <v-password [model]="PasswordModel" [(value)]="Data.password"></v-password>
      <a [routerLink]="'/fogotpassword'" style="display:inline-block; margin-bottom:24px;" class="green-link forgotpass-link marginb24">Forgot password?</a>
      <br />
      <p class="ok-button button-green " (click)="loginEnd()">Log in</p>

      <p class="loginpanel__or">OR</p>
      <autorizationgoogle-wiget [loginError]="loginError"></autorizationgoogle-wiget>
      <p class="singup-link-wrapper">No account?  <a [routerLink]="'/registration'" class="green-link singup-link">Create one</a></p>
    </div> 
    <div class="login-servererror loginpanel" [ngClass]="loginError.isThrow?'':'hide'">
      <div style="height:56px;">
        <h3 class="loginpanel__title display-block">Error</h3>
      </div>
      <div class="login-servererror-wrap">
        <p class="text1424">{{loginError.loginMessage}}</p>
      </div>
      <p class="login-tryagain" (click)="loginError.isThrow=false">Try again</p>

    </div>
  </div>
</div>

