import * as settingsdata from '../../assets/data/settings.json';
import { RouteNames } from './routenames';
import * as telegramdescritiondata from '../../assets/data/telegramdescrition.json';
export interface telegramdescrition {
  list: string[];
}
export interface settings {
  TelegramBotName: string;
  DisqusPublicKey: string;
}

export const Settings: settings = JSON.parse(JSON.stringify(settingsdata));
export const TelegramDescrition: telegramdescrition = JSON.parse(JSON.stringify(telegramdescritiondata));
