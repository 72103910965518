//inner
import { Component,  OnInit,  ChangeDetectorRef,  EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'visible-comp',
  template: '<div></div>'
})
export class VisibleComponent{
  constructor() {
    this.visibleChange = new EventEmitter<boolean>();
    this._visible = false;
  }
  @Output()
  public visibleChange : EventEmitter<boolean>;
  @Input()
  set visible(val: boolean) {
    if (this._visible != val) {
      this._visible = val;
      this.visibleChange.emit(this._visible);
    }
  }
  get visible(): boolean {
    return this._visible;
  }
  _visible: boolean=false;

}
