import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, Validators } from '@angular/forms';
import { Customvalidators, Guid, IUserCred, PasswordValidator, States } from '../../../classes';
import { Helper, RouteNames } from '../../../classes';
import { Dialog, VInputModel } from '../../../components';
import { HttpService } from '../../../services/http.service';

class SignUpModel  {
  public name: string='';
  public email: string = '';
  public phone: string = '';
  public password: string = '';
}

@Component({
  templateUrl: './registration.component.html'
})
export class RegistrationComponent implements OnInit{
  ngOnInit() {

  }

  static Path: "Registration"
  public Data: SignUpModel = new SignUpModel();
  public Authorized: boolean = false;
  public IsRegistration = false;
  public loginMessage: any = "";
  public resenderror = false;
  public resendMessage: any = "";
  public IsAgree = false;
  public PhoneMask = { Mask: '(000) 000 0000' };
  public EmailMask = Helper.EmailMask;
  public EmailPlaceHolder = 'yourmail@gmail.com';
  public StateList = States;

  public PhoneModel = new VInputModel({
    Label: '',
    errors: [{ key: 'required', value: 'Phone is required' }],
    Validators: [Validators.required],
  });  

  public NameModel = new VInputModel({
    Label: 'First name',
    errors: [{ key: 'required', value: 'First name is required' }],
    Validators: [Validators.required],
  });

  public EmailModel = new VInputModel({
    Label: 'yourmail@gmail.com',
    errors: [
      { key: 'required', value: 'Email is required' },
      { key: 'Email', value: 'Please enter a valid Email' }
    ],
    Validators: [Customvalidators.EmailValidator()],
  });

  public PasswordModel = new VInputModel({
    Label: '',
    errors: [{ key: 'required', value: 'Password is required' }],
    Validators: [Validators.required],
  });
  //public ConfirmPasswordModel = new VInputModel({
  //  Label: '',
  //  errors: [
  //    { key: 'required', value: 'Password is required' },
  //    { key: 'notsame', value: 'The passwords entered are not the same' }
  //  ],
  //  Validators: [PasswordValidator(this.Data)],
  //});

  public get CanBeDone() { 
 //   let Phone = this.PhoneModel.Validate();    
    let Name = this.NameModel.Validate();
    let Password = this.PasswordModel.Validate();
    return  Name && Password && this.EmailModel.Validate();
  }
  constructor(public client: HttpService, private router: Router) {
    this.Authorized = HttpService.Authorized;
 
  }

  public signUp() {
    if (this.CanBeDone)
      this.client.CreteAccount(this.Data, (error: any) => {
        this.IsRegistration = true;
        if (error.error != null && error.error.detail != null)
          this.loginMessage = error.error.detail;
        else
          this.loginMessage = error.message;
        console.log(error);
      }).subscribe((res) => {
        this.client.navigate([RouteNames.Login]);
      });
  }   


  public resendEmail() {
    //if (this.IsRegistration)
    //  this.client.ForgotPassword(this.Data.Email, (error) => {
    //    console.log(error);
    //    this.resenderror = true;
    //    this.resendMessage = error.message;
    //  }).subscribe((r) => {
    //    this.client.navigate([RouteNames.Login]);
    //  });
  }
} 
