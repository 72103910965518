import { Component,  OnInit, ElementRef, Input, EventEmitter, Output, OnDestroy } from '@angular/core';

import { Observable, BehaviorSubject} from 'rxjs';
import { Helper } from '../../classes';

@Component({
  selector: 'icon-dropdown',
  templateUrl: './IconDropDown.component.html'
})

export class IconDropDown implements OnInit, OnDestroy {  
  @Output()
  public openChange = new EventEmitter<boolean>();
  @Input()
  get open() {
    return this._open;
  }
  set open(val) {
    if (this._open == val) return;
    this._open = val;
    this.openChange.emit(this._open );
  }
  private _open = false;

  @Input()
  get dropdownclass() {
    return this._dropdownclass;
  }
  set dropdownclass(val) {
    this._dropdownclass = val;
  }
  private _dropdownclass: string = "";

  @Input()
  get icon() {
    return this._icon;
  }
  set icon(val) {
    this._icon = val;
}
  private _icon: any;

  constructor(private elRef: ElementRef) { }
  closehandler(e:any) {
    let root = this.elRef.nativeElement;
    if (!root.contains(e.target)) {
      this.open = false;
    }
  }
  ngOnInit(): void {
    window.addEventListener('click', this.closehandler.bind(this));
  }
  ngOnDestroy() {
    window.removeEventListener('click', this.closehandler);
  }  
}


