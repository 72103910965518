<div class="dropdown" [ngClass]="open?'':'dropdown-close'"  autocomplete="off" style="max-height:160px">
  <div class="dropdown-data-wrap">
    <div class="dropdown-content">
      <div class="datastore-select-list gray-scrollbar">
        <p *ngFor="let item of fitems" class="ellipsis" (click)="setvalue(item); open=false;">{{getdisplayExp(item)}}</p>
      </div>
    </div>
  </div>
  <mat-form-field class="mat-dropdown mat-input-suffix-icon mat-input-suffix-dropdown">  
      <mat-label>{{label}}</mat-label>
      <input matInput type="text" [formControl]="FormControl" [errorStateMatcher]="Matcher"  (keyup)="keypress($event)" >
      <p [ngClass]="open?'icon-dropup':'icon-dropdown'" matSuffix (click)="open=!open"></p>
      <mat-error *ngFor="let item of errors" [ngClass]="hasError(item.key)?'':'hide'">{{item.value}}</mat-error>
  </mat-form-field>
</div>
