import { Helper } from "./helper";


export class Guid {
    constructor(public guid: any) {
        this._guid = guid;
    }

    private _guid: any;
    public static Empty = Guid.generate(0);//"00000000-0000-0000-0000-000000000000";
    public static FFFF = Guid.generate(15);//FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF
    public static AAAA = Guid.generate(10);
    public static BBBB = Guid.generate(11);
  public static EmptyStr = Guid.Empty.ToString();
  public static AAAAStr = Guid.AAAA.ToString();
  public static BBBBStr = Guid.BBBB.ToString();
  public ToString(): any {
        return this._guid;
    }
    
    toJSON() {
        return this._guid;
    }

    // Static member
    static NewGuid(): Guid {
        var result: any;
        var i: any;
        var j: number=0;

        result = "";
        for (j = 0; j < 32; j++) {
            if (j == 8 || j == 12 || j == 16 || j == 20)
            result = result + '-';
          i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
            result = result + i;
        }
        return new Guid(result);
  }
  
  static NewGuidStr(): any {
    return Guid.NewGuid().ToString();
  }

  static Combine(A: any, B: any) {
    let result: any='';
    let parse = (a: any) =>
    {
      let s = a.toLowerCase();
      let n: number=0;// = Number.parseInt(s, 10);
      switch (s) {
        case 'a': n = 10; break;
        case 'b': n = 11; break;
        case 'c': n = 12; break;
        case 'd': n = 13; break;
        case 'e': n = 14; break;
        case 'f': n = 15; break;
        default:
          n = Number.parseInt(s, 10);
          break
      }
      return n;
    };
    let tohex = (a: number) => {
      let s = '';
      switch (a) {
        case 10: s = 'a'; break;
        case 11: s = 'b'; break;
        case 12: s = 'c'; break;
        case 13: s = 'd'; break;
        case 14: s = 'e'; break;
        case 15: s = 'f'; break;
        default:
          s = a+'';
          break
      }
      return s;
    };
    let sum = (a: any, b: any) => {
      let na: number = parse(a);
      let nb: number = parse(b);
      let res = na + nb;
      if (res > 15)
        res = res - 16;
      return tohex(res);
    };
    if (Helper.NotEmpty(A) && Helper.NotEmpty(B) && A.length == 36 && B.length == 36) {
      for (let j = 0; j < 36; j++) {
        if (A[j]=='-')
          result = result + '-';
        else {
          result += sum(A[j], B[j]);
        }
      
      }
      return result;
    }
    return Guid.EmptyStr;
  }

    static generate(num: number): Guid {
        var result: any;
        var i: any;
        var j: number=0;

        result = "";
        for (j = 0; j < 32; j++) {
            if (j == 8 || j == 12 || j == 16 || j == 20)
            result = result + '-';
          i = num.toString(16).toUpperCase();
            result = result + i;
        }
        return new Guid(result);
    }
}
