import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Validators } from '@angular/forms';
import { VInputModel } from '../../../components';
import { HttpService } from '../../../services/http.service';
import { Customvalidators, Helper, LoginServerError, RouteNames } from '../../../classes';
import { Settings, settings } from '../../../classes';
class LoginModel {
  public email: string='';
  public password: string='';
}

@Component({
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit{
  public currentApplicationVersion = '1.0.0';// environment.appVersion;
  static Path: "Login"
  public EmailMask = Helper.EmailMask;
  public EmailPlaceHolder = 'yourmail@gmail.com';

  public Data = new LoginModel();
  public code: any = '';
  public remember: boolean = false;
  public Authorized: boolean = false;


  loginError: LoginServerError = new LoginServerError();
  ngOnInit() {
  }

  public EmailModel = new VInputModel({
    Label: 'E-mail',
    errors: [
      { key: 'required', value: 'Login is required' },
      { key: 'Email', value: 'Please enter a valid E-mail' }
    ],
  //  Validators: [Validators.required],
    Validators: [Customvalidators.EmailValidator()],
  });
  public PasswordModel = new VInputModel({
    Label: 'Password',
    errors: [{ key: 'required', value: 'Password is required' }],
    Validators: [Validators.required],
  });
  public get CanBeDone() {
    return this.PasswordModel.Validate() && this.EmailModel.Validate();
  }

  constructor(public client: HttpService, private router: Router) {
    this.remember = false;
    this.Authorized = HttpService.Authorized;
    this.Authorized = HttpService.Authorized;
    HttpService.authorizedObserver.subscribe(Authorized => {
      if (HttpService.Authorized) {
        this.Authorized = HttpService.Authorized;
        this.client.navigate([RouteNames.Dashboard]);
      }
    });
  }

  public errorMessage = "";
  loginEnd() {
    this.loginError.loginMessage = "";
    this.loginOnServer();
  }

  public qrcode = '';
  public key: any = '';
  public state: string = 'login';
  imgex = false;
  public token = '';
  Mask = { Mask:'000000'};
 
  loginOnServer(remember: boolean = false) {
    let that = this;
    if (this.CanBeDone)
      this.client.login(this.Data, that.remember
      ).subscribe(
        (res: any) => {
          this.token = res.token;
          const at = Helper.parseJwt(this.token);
          const totp = at.totp;
          if (totp == null) {
            that.client.setToken(this.token);
          }
          else {
            switch (totp) {
              case 'init':
                that.UpdatecodeModel();
                that.state = 'init';
                break;
              case 'required':
                that.UpdatecodeModel();
                that.state = 'required';
                break;
              default:
                that.client.setToken(this.token);
                break;
            }
          }
        },
        (error: any) => {
          if (error.status == 401) {
            that.loginError.loginMessage = 'Username or password is incorrect';
          }
          else if (error.status = 412) {
            that.loginError = {
              isThrow: true,
              loginMessage: 'Your email has not been confirmed. Please check your email for a confirmation email.'
            };
          }
          else if (error.status = 429) {
            that.loginError.loginMessage = 'Too Many Requests. Please try again later.';
          }
          else {
            that.loginError.loginMessage = 'No connection could be made because the target machine actively refused it.';
          }
          that.loginError.isThrow = true;
        }
      );
  }

  UpdatecodeModel() {
    //let that = this;
    //that.code = '';
    //that.codemodel =  new VInputModel({
    //  Label: '',
    //  errors: [{ key: 'required', value: 'The entered code must contains 6 digits' },
    //  { key: 'totpcode', value: 'The entered code may be wrong or outdated' } ],
    //  AsyncValidators: [TotpCodeValidator(that)],
    //});
  }

  ValidateEnd() {
    this.Validate();
  }

  public Validate() {
    let that = this;
  }

  clear() {
    this.Data = new LoginModel();
  }


  SelectAnotherUser() {
    this.token = '';
    this.state = 'login';
    this.clear();
    this.EmailModel.Untouched();
    this.PasswordModel.Untouched();
  }
  CopyToClipboard() {
    let that = this;
    navigator.clipboard.writeText(that.key)
      .then(() => {
        // Получилось!
      })
      .catch(err => {
        console.log('Something went wrong', err);
      });
  }
} 
