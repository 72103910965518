<wizard [data]="wizard" class="wizard" [(visible)]="visible">
  <div *ngIf="wizard.stepnum==0" style="height:290px; margin-top:30px;">
    <v-input [model]="NameModel" [(value)]="model.comment_system.name"> </v-input>
    <div [hidden]="edit">
      <vselect-box displayExp="name" keyExp="value" [items]="Types" [data]="TypeModel"
                   [(value)]="model.comment_system.type">
      </vselect-box>
    </div>
    <div *ngIf="!edit">
      <div *ngIf="model.comment_system.type==accountTypes.Telegram">
        <telegram-autorization-component [model]="model"></telegram-autorization-component>
      </div>
      <!-- <p class="ok-button button-green " (click)="Autorize()">Send</p> -->
    </div>
    <div *ngIf="model.comment_system.type==accountTypes.Disqus">
      <v-input [model]="ApiKeyModel" [(value)]="model.comment_system.api_key"> </v-input>
      <v-input [model]="AccessTokenModel" [(value)]="model.comment_system.access_token"> </v-input>
    </div>
  </div>
  <div *ngIf="wizard.stepnum==1" style="height:290px; margin-top:30px;">
    <v-input [model]="NameModel" [(value)]="model.comment_system.name"> </v-input>    
  </div>
  <div *ngIf="wizard.stepnum==2">
    <div class="website-wrapper" style="height:250px;">
      <div class="gray-scrollbar">
        <div class="website-item" [ngClass]="{'website-item-active': item.checked, 'disabled-with-opacity': item.disabled}"
             *ngFor="let item of websites">
          <p class="website-item__checkbox" (click)="item.checked=!item.checked"></p>
          <p class="website-item__name"> {{GetSiteName(item)}}</p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="wizard.stepnum==3" class="telegram-info wizard-content-with-margin">
    <div>
      <p *ngFor="let item of TelegramDescritionList">
        {{item}}
      </p>
    </div>
  </div>
  <div *ngIf="wizard.stepnum==4">
    <topicsettings-item *ngFor="let item of TopicSettingsList" [data]="item">
    </topicsettings-item>
    <div class="website-item" style="padding-left: 18px" [ngClass]="restoreSettings?'website-item-active':''">
      <p class="website-item__checkbox" (click)="restoreSettings=!restoreSettings"></p>
      <p class="website-item__name">Restore the default settings</p>
    </div>
  </div>
  <div *ngIf="wizard.stepnum==5" class="wizard-content-with-margin">
    <label class="radio-container">
      From the current moment
      <input type="radio" name="radio" [checked]="selectedStartValue === startValues.Current"
             [(ngModel)]="selectedStartValue" [value]="startValues.Current">
      <span class="checkmark"></span>
    </label>
    <div class="calendar-input-container">
      <label class="radio-container">
        Process starting from
        <input type="radio" name="radio" [checked]="selectedStartValue === startValues.StartingFrom"
               [(ngModel)]="selectedStartValue" [value]="startValues.StartingFrom">
        <span class="checkmark"></span>
      </label>
      <span *ngIf="selectedStartValue == startValues.StartingFrom">
        <calendar (onDateSelect)="selectDate($event)"></calendar>
      </span>
    </div>
  </div>
</wizard>
