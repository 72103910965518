import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  WizardComponent,  DialogComponent,   VInput, ValidateionSelectBox, VisibleComponent} from '.';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialAllModule } from '../material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask, IConfig } from 'ngx-mask'
//import { VDateTimePicker } from './VInput/VDateTimePicker';
import { VPassword } from './VInput/VPassword.component';
import { ValidateionSearchBox } from './VInput/ValidateionSearchBox.component';
import { DropDown } from './DropDown/DropDown.component';
import { PeriodSelectorComponent } from './PeriodSelector/PeriodSelector.component';
import { VTextarea } from './VInput/VTextarea.component';
import { SearchInput } from './SearchInput/SearchInput.component';
import { MultySelectBox } from './MultySelectBox/MultySelectBox.component';
import { TabsMenuComponent } from './TabsMenu/TabsMenu.component';
import { IconDropDown } from './IconDropDown/IconDropDown.component';
import { MonthNavigator } from './MonthNavigator/MonthNavigator.component';
import { RangeSlider } from './RangeSlider/RangeSlider.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  imports: [
    CommonModule, RouterModule, FormsModule, ReactiveFormsModule, MaterialAllModule, NgbModule,  NgxMaskDirective, NgxMaskPipe
  ],
  declarations: [WizardComponent, DialogComponent, TabsMenuComponent, ValidateionSelectBox, ValidateionSearchBox, DropDown, IconDropDown, PeriodSelectorComponent, SearchInput, MultySelectBox,
    VInput, VPassword, VTextarea, MonthNavigator, RangeSlider,// VDateTimePicker,
    VisibleComponent],
  exports: [
    WizardComponent, DialogComponent, ValidateionSelectBox, TabsMenuComponent, ValidateionSearchBox, DropDown, IconDropDown, PeriodSelectorComponent, SearchInput, MultySelectBox,
    VInput, VPassword, VTextarea, MonthNavigator, RangeSlider,// VDateTimePicker,
    VisibleComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [provideNgxMask(maskConfig)]
})
export class CommonComponentsModule { }
