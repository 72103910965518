<div class="loginform">
  <div>
    <div class="loginpanel" *ngIf="!IsRegistration">
      <div class="loginpanel__img"></div>
      <p class="loginpanel__title marginb32">Sign up</p>
      <v-input [model]="NameModel" [(value)]="Data.name"> </v-input>
      <v-input [model]="EmailModel"
               [(value)]="Data.email"
               [InputPlaceholder]="EmailPlaceHolder"
               [Mask]="EmailMask"
               [ShowMaskTyped]="false"> </v-input>
      <v-password [model]="PasswordModel" [(value)]="Data.password"></v-password>
      <p class="ok-button button-green margint24" (click)="signUp()">Create account</p>   
    </div>
    <div class="login-servererror loginpanel" *ngIf="IsRegistration">
      <div style="height:56px;">
        <h3 class="loginpanel__title display-block">Error</h3>
      </div>
      <div class="login-servererror-wrap">
        <p class="text1424">{{loginMessage}}</p>
      </div>
      <p class="login-tryagain" (click)="IsRegistration=false">Try again</p>

    </div>
  </div>
</div>
