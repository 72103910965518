import { Component, Input, OnInit } from '@angular/core';
import { Dialog, VisibleComponent, Wizard } from '../../../components';
import {
  CSWizardSettings, CommentSystemForCreationDto, Helper,
  SiteForCreationDto, TopicAction, TopicSettingWizard,
} from '../../../classes';
import { HttpService } from '../../../services/http.service';
import { TopicSettingWizardData } from './topicsettings.item';
import { DashboardActionProvider } from '../DashboardActionProvider';



@Component({
  selector: 'sitesettings-dialog',
  templateUrl: './sitesettings.dialog.html'
})

export class SiteSettingsDialog extends VisibleComponent implements OnInit{
  constructor(public client: HttpService) {
    super();
  }
  @Input()
  set context(val: DashboardActionProvider) {
    this._context = val;
  }
  get context(): DashboardActionProvider | undefined {
    return this._context;
  }
  _context: DashboardActionProvider | undefined;
  public id: string = '';
  public TopicTypes: { name: string, value: number }[] = [];

  ngOnInit() {
    let that = this;
    this.client.GetTypes().then((types) => {     
      that.TopicTypes = types.GetList('topic_types');
    });    
  }

  TopicSettingsList: TopicSettingWizardData[] = [];
  mapTopicSettingWizard(list: TopicSettingWizard[]) {
    let res: TopicSettingWizardData[] = [];
    let dic: { [id: string]: TopicSettingWizard[] } = {};
    let types: { [id: string]: { name: string, value: number } } = {};
    this.TopicTypes.forEach((tt) => {
      let id = tt.value + '';
      types[id] = tt;
    });
    list.forEach((item) => {
      let id = item.type + '';
      if (dic[id] != null) {
        dic[id][dic[id].length] = item;
      }
      else {
        dic[id] = [item];
      }
    });
    Object.keys(dic).forEach((key) => {
      let data = new TopicSettingWizardData();
      let tslist = dic[key];
      data.name = types[key].name;
      data.type = types[key].value;

      tslist.forEach(ts => {
        data.checked = ts.isActive;
        if (ts.action == TopicAction.Block) {
          data.block = ts.value;
        }
        if (ts.action == TopicAction.Tag) {
          data.warning = ts.value;
        }
      });
      res[res.length] = data;
    });
    return res;
  }
 
  wizard: Wizard = new Wizard(
    {
      wizardstyle: 'oncomments',
      likedialog: true,
      width:600,
      steps: [       
        {
          title: 'Custom moderation settings',
          subtitle: 'Assign custom thresholds values.'
        },
      ],
      OnOpen: () => {
        this.wizard.isloading = true;
        if (this.context != null && this.context.SelectedSite != null)
          this.id = this.context.SelectedSite.site.id;
        this.client.get<any>(`api/site/${this.id}/topicsettings`).subscribe((res) => {
          console.log(res);
          this.TopicSettingsList = this.mapTopicSettingWizard(res);
          this.wizard.isloading = false;
        }, (error) => {
          this.wizard.isloading = false;
        });
      },
      Finish: () => {
        let tslist: TopicSettingWizard[] = [];
        this.TopicSettingsList.forEach((ts) => {
          let tsw = new TopicSettingWizard();
          tsw.action = TopicAction.Tag;
          tsw.isActive = ts.checked;
          tsw.type = ts.type;
          tsw.value = ts.warning;
          tslist[tslist.length] = tsw;
          let tsb = new TopicSettingWizard();
          tsb.action = TopicAction.Block;
          tsb.isActive = ts.checked;
          tsb.type = ts.type;
          tsb.value = ts.block;
          tslist[tslist.length] = tsb;
        });
       
        this.client.put(`api/site/${this.id}/topicsettings`, tslist).subscribe((res) => {
          this.context?.Load();
        });
      }
    });

 

} 

