<wizard [data]="wizard" class="wizard" [(visible)]="visible">
    <div *ngIf="wizard.stepnum==0" class="radiobuttons-container">
        <label class="radio-container">From the current moment
            <input type="radio" name="radio" [checked]="selectedValue === resumeValues.Current"
                [(ngModel)]="selectedValue" [value]="resumeValues.Current" (input)="onSelectedValueChange()">
            <span class="checkmark"></span>
        </label>
        <label class="radio-container" *ngIf="!isResumeAll">Process all unprocessed
            <input type="radio" name="radio" [checked]="selectedValue === resumeValues.Unprocessed"
                [(ngModel)]="selectedValue" [value]="resumeValues.Unprocessed" (input)="onSelectedValueChange()">
            <span class="checkmark"></span>
        </label>
        <div class="calendar-input-container">
            <label class="radio-container">Process starting from
                <input type="radio" name="radio" [checked]="selectedValue === resumeValues.StartingFrom"
                    [(ngModel)]="selectedValue" [value]="resumeValues.StartingFrom" (input)="onSelectedValueChange()">
                <span class="checkmark"></span>
            </label>
            <span *ngIf="selectedValue == resumeValues.StartingFrom">
                <calendar (onDateSelect)="selectDate($event)"></calendar>
            </span>
        </div>
    </div>
</wizard>