import { Component,  OnInit, ElementRef, Input, EventEmitter, Output } from '@angular/core';

import { Observable, BehaviorSubject} from 'rxjs';
import { Helper } from '../../classes';
import { ValidatorFn, AsyncValidatorFn, FormGroup, AbstractControl, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { VInputModel } from '..';



@Component({
  selector: 'v-password',
  templateUrl: './VPassword.component.html'
})

export class VPassword{
  public showpass = false;
  @Output()
  public valueChange = new EventEmitter<any>();
  @Input()
  set value(val) {
    if (this._value == val) return;
    this._value = val;
    if (this.model != null && this.model.FormControl.value != val) {
      this.model.FormControl.setValue(val);
    }
    this.valueChange.emit(this._value);
  }
  get value() {
    return this._value;
  }
  private _value: any;
  @Input()
  set hidepass(val) {
    if (this._hidepass == val) return;
    this._hidepass = val;
  }
  get hidepass() {
    return this._hidepass;
  }

  @Output()
  public OnEnterPress = new EventEmitter();
  keypress($event: any) {
    if ($event.keyCode == 13) {
      this.OnEnterPress.emit();
    }
  }
  private _hidepass: boolean = false;
  
  @Input()
  set model(val) {
    if (val == null) return;
    this._model = val;
    let that = this;
    let value = this.value;
    this._model.FormControl.valueChanges.subscribe(v => {
      that.value = v;
    });
    if (val.Label != null && val.Label.length > 0)
      this.label = val.Label;
    if (Helper.NotEmpty(value))
      this.model.FormControl.setValue(value);
  }
  get model() {
    return this._model;
  }
  private _model = VInputModel.Defualt();

  label = '';
  @Input()
  public set Label(val) {
    if (val == null) return;
    this.label = val;
  }
  public get Label() {
    return this.label;
  }

  public get errors() {
    return this.model.errors;
  }

  public Validate() {
    this.model.Validate();
  }
  public hasError(key:any) {
    return this.model.FormControl.hasError(key);
  }
  public get Matcher() {
    return this.model.Matcher;
  }
  public get isdefault() {
    return this.model.isdefault;
  }
  public get FormControl() {
    return this.model.FormControl;
  }
}
