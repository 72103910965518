import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, Validators } from '@angular/forms';
import { VInputModel } from '../../../components';
import { Helper, IUserCred, PasswordValidator, RouteNames } from '../../../classes';
import { HttpService } from '../../../services/http.service';

class ResetPasswordModel implements IUserCred {
  public Password: string = '';
  public ConfirmPassword: string = '';
}

@Component({
  templateUrl: './resetpassword.component.html'
})
export class ResetPasswordComponent implements OnInit{
  public resenderror = false;
  public resendMessage: any = "";
  public Data = new ResetPasswordModel();
  public PasswordModel = new VInputModel({
    Label: '',
    errors: [{ key: 'required', value: 'Password is required' }],
    Validators: [Validators.required],
  });
  public ConfirmPasswordModel = new VInputModel({
    Label: '',
    errors: [
      { key: 'required', value: 'Password is required' },
      { key: 'notsame', value: 'The passwords entered are not the same' }
    ],
    Validators: [PasswordValidator(this.Data)],
  });
  public wasSending = false;
  public email: string = '';
  public accessToken: string = '';
  //:email/:code`
  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['email'] && params['code']) {
        this.email = params['email'];
        this.accessToken = params['code'];
      }
    });
  }
  public get CanBeDone() {
    let p = this.PasswordModel.Validate();
    this.ConfirmPasswordModel.FormControl.updateValueAndValidity();
    let pc = this.ConfirmPasswordModel.Validate();
    return p && pc;
  }
  constructor(public client: HttpService, private route: ActivatedRoute) {   
  }
  //AccessToken
  public resetPassord() {
    if (this.CanBeDone) {
      this.client.ResetPassword({ email: this.email, password: this.Data.Password, accessToken: this.accessToken }).subscribe(
        (res) => {
          this.wasSending = true;
        },
        (error) => {
          this.resendMessage = Helper.GetErrorMessage(error);
          this.resenderror = true;
        });
    }
  }
} 
