import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { Guid } from "./guid";
export class DataGridSettings {
    public headerHeight = 60;
    public rowHeight = 64;
    public rowHeightLow = 32;
    public isLoading: boolean = false;
    public clearselected: boolean = false;
    public ColumnMode = ColumnMode;
    public SelectionType = SelectionType;
}
export interface SitesStatistics {
    site: {
        id: string;
        name: string;
        name_id: string;
        state: number;
      comment_start_time: string;
       is_deleted: boolean;
    };
    statistics: {
        number_of_blocked: number;
        number_of_processed: number;
        number_of_tagged: number;
    }
}
export interface CommentSystemData {
    comment_system: {
        id: string;
        name: string;
        type: number;
    };
    sites_and_statistics: SitesStatistics[];
}

export interface KeyValueType { key: string, value: number }
export type TypesDto = { [id: string]: { [id: string]: number } }
//comment_system_types: { [id: string]: number };
//topic_types: { [id: string]: number };
//topic_action_types: { [id: string]: number };

export class CommonTypes {
    public Lists: { [id: string]: { name: string, value: number }[] } = {};
    public Dictionries: { [id: string]: { [id: string]: string } } = {};
    constructor(data: TypesDto) {
        let TypesList: { name: string, value: number }[] = [];
        Object.keys(data).forEach((key) => {
            let typesdto = data[key];
            let TypesList: { name: string, value: number }[] = [];
            let TypesDic: { [id: string]: string } = {};
            Object.keys(typesdto).forEach((tkey) => {
                let val = typesdto[tkey];
                TypesList[TypesList.length] = { name: tkey, value: val };
                let valkey = val + '';
                TypesDic[valkey] = tkey;
            });
            this.Lists[key] = TypesList;
            this.Dictionries[key] = TypesDic;
        });
    }
    GetList(name: string) {
        let result = this.Lists[name];
        return result != null ? result : [];
    }
    GetTypeNameById(tname: string, id: number) {
        let result = this.Dictionries[tname];
        if (result != null) {
            let key = id + '';
            let name = result[key];
            return name != null ? name : '';
        }
        else return '';
    }
}
export class CommentSystemForCreationDto {
    public id: string = Guid.NewGuidStr();// "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    public name: string = '';
    public login: string = '';
    public password: string = '';
    public auth: string = '';
    public api_key: string = '';
    public access_token: string = '';
    public type: number = 0;
}


export class SiteForCreationDto {
    public id: string = Guid.NewGuidStr();
    public comment_system_id: string = Guid.NewGuidStr();
    public name: string = '';
    public name_id: string = '';
    public moderate_interval: string = '01:00:00';
    public moderate_edited_interval: string = '01:00:00';
    public moderate_edited_depth: string = '01:00:00';
    public edited_lifetime: string = '01:00:00';
    public context_depth: number = 1;
    public isActive: boolean = true;
}

export enum TopicAction {
    None = 0,
    Tag = 1,
    Block = 2,
}
export enum AccountEnum {
    Disqus = 1,
    Telegram = 2,
}
export class TopicSettingWizard {
  // [Range(1, 10, ErrorMessage = "value must be 1 to 10")]
    public id: string = Guid.NewGuidStr();
    public value: number = 1;
    public action: TopicAction = 1;
    public type: number = 1;
    public isActive: boolean = true;
}

export class CSWizardSettings {
    comment_system: CommentSystemForCreationDto = new CommentSystemForCreationDto();
    sites: SiteForCreationDto[] = []
    topic_settings: TopicSettingWizard[] = []
    comment_start_time: string = ''
}
export class PaymentInfoDto {
    type_card?: string;
    last_four_card?: string;
    expiry_year_card?: string;
    expiry_month_card?: string;
}
export class BalanceAndQuotaDto {
    quota: number = 0;
    balance: number = 0;
    actual_cost: number = 0;
    payment_due: number = 0;
}
export class InvoiceDto {
    amount: number = 0;
    status?: string;
    date: any = '';
    type_card: string = '';
    last_four_card?: string;
}
export class PriceDto {
    remote_id: string = '';
    product_code: string = '';
    is_next: boolean | undefined;
    is_active: boolean = false;
    amount: number = 0;
    limit: number = 0;
    above: number = 0;
}

export interface IAutrizationJobParameters {
    id: string;
    type: number;
    redirecturi: string;
    code: string;
}
export class LoginServerError {
    public isThrow = false;
    public loginMessage: any = "";
}
