export enum UserManagementRole {
  Admin,
  LoanOfficer
}
export enum UserManagementStatus {
  Active,
  Pending
}


export class UserModel {
  public id: string = '';
  public phone?: string = '';
  public name: string = '';
  public email: string = 'sombeody@gamil.com';
}
