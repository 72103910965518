import { Component, Injectable, Input, Output, EventEmitter } from '@angular/core';
import { Helper } from '../../classes';


@Component({
  selector: 'search-input',
  templateUrl: './SearchInput.component.html'
})

export class SearchInput{
  @Output()
  public editedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input()
  set edited(val: boolean) {
    if (this._edited != val) {
      this._edited = val;
      this.value = '';
      this.editedChange.emit(this._edited);
    }
  }
  get edited(): boolean {
    return this._edited;
  }
  _edited: boolean = false;
  @Output()
  public valueChange = new EventEmitter<any>();

  @Input()
  set value(val) {
    if (!Helper.NotEmpty(val)) {
      this.word = '';
    }
    if (this._value != val) {
      this._value = val;
      this.word = this._value;
      this.valueChange.emit(this._value);
    }
  }
  get value() {
    return this._value;
  }
  private _value: any = '';

  public word: any;
  _placeholder: any = 'Search';
  @Input()
  get placeholder(): any {
    return this._placeholder;
  }
  set placeholder(val: any) {
    this._placeholder = val;
  }

  constructor() { }

  keypress($event: any) {
    if ($event.keyCode == 13) {
      this.filter();
    }
  }
  public oninput(event: any) {
    this.word = event.target.value;
    this.edited = true;
  }

  public filter() {
    this.value = this.word;
  }
}

