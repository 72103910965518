import { Component,  OnInit, ElementRef, Input, EventEmitter, Output, OnDestroy } from '@angular/core';

import { Observable, BehaviorSubject} from 'rxjs';
import { Helper } from '../../classes';
import { PageMenuItem } from '../../components';

class Notification {
  Name: string = '';
  CreationDate: Date = new Date();
  Viewed: boolean = false;
}

@Component({
  selector: 'row-actions',
  templateUrl: './RowActions.component.html'
})

export class RowActionsComponent implements OnInit, OnDestroy {
  public open = false;
  @Input()
  get value() {
    return this._value;
  }
  set value(val: any) {
    this._value = val;
  } 
  private _value: any;

  _actions: PageMenuItem[] = [];
  @Input()
  get actions() {
    return this._actions;
  }
  set actions(val: any) {
    this._actions = val;   
  }
 
  constructor() { }
 
  ngOnInit(): void {
  
  }
  ngOnDestroy() {
   
  }
  close(){
    this.open = false;
  }
}


