import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, Validators } from '@angular/forms';
import { VInputModel } from '../../../components';
import { Guid, Helper, IUserCred, LoginServerError, PasswordValidator, RouteNames } from '../../../classes';
import { HttpService } from '../../../services/http.service';
import { Observable, Subscription } from 'rxjs';
import { GoogleAuthService } from '../../../services/google-auth.service';


@Component({
  selector: 'autorizationgoogle-wiget',
  templateUrl: './autorizationgoogle.wiget.html'
})

export class AutorizationGoogleWiget implements OnInit{
  title = 'Codingvila Login With Google';
  
  constructor(
    public client: HttpService,
    private googleAuthService: GoogleAuthService
  ) {
   
  }
  ngOnInit() {
    let that = this;
    this.subs.push(that.googleAuthService.googleData.subscribe(x => {
      let data = {
        Provider: 'Google',
        IdToken: x.tokenId
      };
      if (data.IdToken != Guid.EmptyStr) {
        this.subs.push(that.client.loginGoogle(data, false).subscribe((res: any) => {
          that.client.setToken(res.token);
          that.unsubscribe();
        },
          (error: any) => {
            if (error.status != 401) {
              that.loginError.loginMessage = 'Username or password is incorrect';

            }
            else {
              if (error.status = 429) {
                that.loginError.loginMessage = 'Too Many Requests. Please try again later.';
              }
              else
                that.loginError.loginMessage = 'No connection could be made because the target machine actively refused it.';
            }
            that.loginError.isThrow = true;
          }
        ));
      }
    })); 
    this.googleAuthService.init();
  }

  @Input()
  set loginError(val: LoginServerError) {
    this._loginError = val;
  }
  get loginError(): LoginServerError {
    return this._loginError;
  }
  _loginError: LoginServerError = new LoginServerError();
  private subs: Subscription[] = [];

  unsubscribe(): void {
    this.subs.forEach(x => x.unsubscribe())
  }
} 


