import { Component,  OnInit, ElementRef, Input, EventEmitter, Output, OnDestroy } from '@angular/core';

import { Observable, BehaviorSubject} from 'rxjs';
import { Helper } from '../../classes';
import { HttpService } from '../../services/http.service';
import { GoogleAuthService } from '../../services/google-auth.service';

@Component({
  selector: 'user-info',
  templateUrl: './UserInfo.component.html'
})

export class UserInfoComponent implements OnInit, OnDestroy {
  UserName: string = '';
  UserEmail: string = '';
  constructor(public client: HttpService, private googleAuthService: GoogleAuthService) {
    this.client.GetCurrentUser().then((u) => {
      this.UserName = u.name;
      this.UserEmail = u.email;
    });
  }
 
  ngOnInit(): void {   
  }
  ngOnDestroy() {
    
  }
  logout() {
    this.googleAuthService.Logout();
    this.client.Logout();  
  }
}


