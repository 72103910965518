<div class="dashboardtable">
    <div class="dashboardtable__top">
        <p class="dashboardtable__title">
            {{CommentSystemType}} | <span class="system-name">{{CommentSystemName}}</span>
        </p>
        <div class="dashboardtable__buttons">
            <div class="dashboardtable__button icon-resume" *ngIf="IsStartAllActive()" (click)="StartAll()"></div>
            <div class="dashboardtable__button icon-pause" *ngIf="IsPauseAllActive()" (click)="PauseAll()"></div>
            <div class="dashboardtable__button icon-edit" (click)="Edit()"></div>
            <div class="dashboardtable__button icon-delete" (click)="Delete()"></div>
        </div>
    </div>
    <div class="dashboardtable__header">
        <div class="dashboardtable__col1">website</div>
        <div class="dashboardtable__col2">verified messages</div>
        <div class="dashboardtable__col3">blocked messages</div>
        <div class="dashboardtable__col4">submitted for manual moderation</div>
        <div class="dashboardtable__col5">marked as unacceptable content</div>
        <div class="dashboardtable__col6"></div>
    </div>
    <div class="dashboardtable__row" *ngFor="let item of data.sites_and_statistics">
        <div class="dashboardtable__col1">
            <span class="site-state" [ngClass]="getSiteClass(item.site)">
                <span class="site-state-description" *ngIf="item?.site?.scheduler_state_description">
                    {{item.site.scheduler_state_description}}
                </span>
            </span>
            {{item.site.name}}
        </div>
        <div class="dashboardtable__col2">{{item.statistics.number_of_processed}}</div>
        <div class="dashboardtable__col3">{{item.statistics.number_of_blocked}}</div>
        <div class="dashboardtable__col4">{{item.statistics.number_of_tagged}}</div>
        <div class="dashboardtable__col5">0</div>
        <div class="dashboardtable__col6">
            <row-actions (click)="rowActionClick(item)" [actions]="menuitems"></row-actions>
        </div>
    </div>
</div>