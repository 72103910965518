<div class="multyselectbox" [ngClass]="open?'':'multyselectbox-close'">
  <div class="multyselectbox-data-wrap ">
    <div class="multyselectbox-content gray-scrollbar">
      <search-input [placeholder]="options.SearchTitle"></search-input>
      <div class="multyselectbox-witoutnone">
        <p class="multyselectbox-witoutnone-title ">{{options.ListCheckboxTitle}}</p>
        <p class="multyselectbox-button" (click)="All()">ALL</p>
        <p class="multyselectbox-button" (click)="None()">NONE</p>
      </div>
      <div class="multyselectbox-list">
        <p *ngFor="let item of Items"
           class="checkbox checkbox-left">
          <input type="checkbox"
                 [(ngModel)]="item.check"
                 (change)="ItemsCheckChange()" />{{GetTitle(item)}}
        </p>
      </div>
    </div>
  </div>
  <p class="multyselectbox-title" [ngClass]="ValueCount==0?'':'multyselectbox-selected'" (click)="open=!open">
    {{label}}
    <span>{{ValueCount}}</span>
  </p>
</div>
