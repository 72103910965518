import {
    AfterViewInit,
    Component,
    ElementRef,
    Input
} from '@angular/core';

import { CSWizardSettings, Settings } from '../../../classes';
import { WindowMassageService } from '../../../services/windowmassage.service';
import { HttpService } from '../../../services/http.service';
export interface User {
    id: number;
    first_name?: string;
    last_name?: string;
    username?: string
    photo_url?: string
    auth_date: number;
    hash: string;
}

// import * as telegram from '../../../services/telegram.js';

// const apiId = 26255456;
// const apiHash = '8b6c0d595f3c37da9b4ef62a3ec82b66';

// const client = new telegram.TelegramClient(new telegram.sessions.StringSession(''), apiId, apiHash, {});
// (async function () {
//     await client.connect();
//     const result = await client.invoke(new telegram.Api.account.ResetWebAuthorization({
//         hash: '123',
//     }));
//     console.log('logout = ', result);
// })();

export type LOGIN_BUTTON_SIZE = 'medium' | 'large' | 'small';
export interface WidgetConfiguration {
    // Login button size. Default: large
    buttonStyle?: LOGIN_BUTTON_SIZE;
    // Show user photo near the button. Default: true
    showUserPhoto?: boolean;
    // Radius of buttons corners(0-20). Default: 20
    cornerRadius?: number;
    // Request for write access. Default: false
    accessToWriteMessages?: boolean
}

@Component({
    selector: 'telegram-autorization-component',
    template: ''
})

export class TelegramWigetComponent implements AfterViewInit {
    private TELEGRAM_WIDGET_VERSION = 22;
    script = {
        name: 'telegram_widget',
        src: `https://telegram.org/js/telegram-widget.js?${this.TELEGRAM_WIDGET_VERSION}`,
        loaded: false,
    };
    @Input()
    set model(val: CSWizardSettings) {
        this._model = val;
    }
    get model(): CSWizardSettings {
        return this._model;
    }
    _model: CSWizardSettings = new CSWizardSettings();

    constructor(private elRef: ElementRef,
        public client: HttpService,
        private windowMassageService: WindowMassageService) {
        this.windowMassageService.telegramUserObserver.subscribe(user => {
            console.log('telegram user =', user);
            if (user != null) {
                this.model.comment_system.auth = JSON.stringify(user);
            }

        });
    }

    ngAfterViewInit() {
        this.loadWidgetScript();
        console.log(localStorage);
    }

    public loadWidgetScript(): void {
        if (this.script.loaded) {
        } else {
            let scriptElement = document.createElement('script');
            scriptElement.type = 'text/javascript';
            scriptElement.src = this.script.src;
            scriptElement.async;
            scriptElement.setAttribute('data-telegram-login', Settings.TelegramBotName);
            scriptElement.setAttribute('data-onauth', 'onTelegramAuth(user)');
            scriptElement.setAttribute('data-size', 'large');
            scriptElement.setAttribute('data-request-access', 'write');
            scriptElement.onload = () => {
                this.script.loaded = true;
            };
            scriptElement.onerror = (error: any) => {
                console.error("Couldn't load script telegram_widget" + error);
            };
            this.elRef.nativeElement.appendChild(scriptElement);
        }
    }
} 
